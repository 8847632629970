import axios from 'axios'

import { IValues } from '../../components/presentationals/formik/forms/Password/Password'
import { API_CHANGE_PASSWORD_URL } from '../../constants/api'

const changePassword = async (token: string, values: IValues): Promise<any> => {
  const response = await axios.post(API_CHANGE_PASSWORD_URL, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response.data
}

export default changePassword
