import { CircularProgress } from '@material-ui/core'
import React, { FC, FormEvent, useEffect, useReducer } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import styled from 'styled-components'
import * as yup from 'yup'

import image from '../../../../assets/images/Im_bloc_NL.png'
import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS } from '../../../../assets/styles/variables'
import {
  MAILCHIMP_NEWSLETTER_FORM_URL,
  MAILCHIMP_SENDING_STATUS
} from '../../../../constants/mailchimp'
import Button from '../../atoms/Button/Button'

interface SectionNewsletterProps {
  className?: string
}

interface MailchimpSubscribeRenderParameters {
  subscribe: any
  status: string
  message: string
}

interface SectionNewsletterState {
  email: string
  isFormValid: boolean
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Veuillez entrez une adresse e-mail valide')
    .required()
})

const initialState: SectionNewsletterState = {
  email: '',
  isFormValid: false
}

enum NewsletterActionType {
  EMAIL_CHANGED,
  FORM_VALIDATED,
  FORM_UNVALIDATED
}

const reducer = (
  state: SectionNewsletterState,
  action: { type: NewsletterActionType; value?: any }
): SectionNewsletterState => {
  switch (action.type) {
    case NewsletterActionType.EMAIL_CHANGED:
      return { ...state, email: action.value }
    case NewsletterActionType.FORM_VALIDATED:
      return { ...state, isFormValid: true }
    case NewsletterActionType.FORM_UNVALIDATED:
      return { ...state, isFormValid: false }
    default:
      return state
  }
}

const Wrapper = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;

  padding: 40px 25px;
  background: none;
  background-color: ${COLORS.white};

  ${media.md`
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 70px 135px 135px 0;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  `};

  div {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    width: 100%;
    margin: auto;
    align-items: flex-start;

    ${media.md`
      align-items: flex-end;
    `};
  }

  h4 {
    font-family: ${FONTS.sansSerif};
    font-size: 1.25rem;
    color: ${COLORS.black};
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;

    ${media.md`
      font-size: 1.5rem;
      margin-top: 1.414rem;
      margin-bottom: .5rem;
    `};
  }

  p {
    margin-bottom: 32px;
    font-family: ${FONTS.brand};
    color: ${COLORS.black};
    font-size: 2rem;

    ${media.md`
      font-size: 2.375rem;
    `};
  }

  small {
    font-family: ${FONTS.brand};
    color: ${COLORS.greenishBlue};
    font-size: 1rem;

    ${media.md`
      font-size: 1.375rem;
    `};
  }

  form {
    display: block;
    width: 100%;

    ${media.md`
      width: auto;
      display: flex;
      flex-direction: row;
    `};
  }

  input {
    height: 48px;
    width: 100%;
    border: solid 1px #585858;
    padding-left: 20px;
    margin-bottom: 20px;

    ${media.md`
      width: 355px;
      margin-right: 20px;
      margin-bottom: 0px;
    `};

    :focus {
      outline: none;
      border: 1px solid ${COLORS.brand};
    }
  }

  ::placeholder {
    color: #585858;
  }
`

const CircularProgressWrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  width: 175px !important;
  color: ${COLORS.brand};

  div {
    margin: 0;
  }
`

const SectionNewsletter: FC<SectionNewsletterProps> = ({ className }) => {
  const [{ email, isFormValid }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    schema.isValid({ email }).then((valid: boolean) =>
      dispatch({
        type: valid ? NewsletterActionType.FORM_VALIDATED : NewsletterActionType.FORM_UNVALIDATED,
        value: valid
      })
    )
  }, [email])

  return (
    <Wrapper className={className}>
      <div>
        <MailchimpSubscribe
          url={MAILCHIMP_NEWSLETTER_FORM_URL}
          render={({ subscribe, status }: MailchimpSubscribeRenderParameters) => {
            if (!status || status === MAILCHIMP_SENDING_STATUS)
              return (
                <>
                  <h4>Newsletters</h4>
                  <p>Restez connectés à Journey</p>
                  <form>
                    <input
                      type="text"
                      placeholder="Votre adresse email"
                      value={email}
                      onChange={(event: FormEvent) =>
                        dispatch({
                          type: NewsletterActionType.EMAIL_CHANGED,
                          value: (event.target as HTMLInputElement).value
                        })
                      }
                    />
                    {!status ? (
                      <Button
                        className="primary"
                        label="Je m'abonne"
                        onClick={() =>
                          subscribe({
                            EMAIL: email
                          })
                        }
                        disabled={!isFormValid}
                      />
                    ) : (
                      <CircularProgressWrapper>
                        <CircularProgress color="inherit" />
                      </CircularProgressWrapper>
                    )}
                  </form>
                </>
              )
            else
              return (
                <>
                  <h4>Newsletter</h4>
                  <p>Merci !</p>
                  <small>Vous recevrez nos news par e-mail !</small>
                </>
              )
          }}
        />
      </div>
    </Wrapper>
  )
}

export default SectionNewsletter
