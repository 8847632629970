import { FormikHelpers } from 'formik'
import get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import signup, { IReturn as ISignup } from '../api/user/signup'
import BaseFormError from '../components/presentationals/atoms/FormError/FormError'
import { MAILCHIMP_NEWSLETTER_FORM_URL } from '../constants/mailchimp'
import Signup, {
  IValues as ISignupValues
} from '../components/presentationals/formik/forms/Signup/Signup'

interface IProps {
  userStore?: any // @todo
  className?: string
}

const Wrapper = styled.div``
const FormError = styled(BaseFormError)`
  margin-top: 16px;
`

function SignupForm(props: IProps) {
  const [error, setError] = useState('')

  const onSubmit = (
    values: ISignupValues,
    actions: FormikHelpers<ISignupValues>,
    subscribeToNewsletter: (email: string) => void
  ) => {
    signup(values)
      .then((data: ISignup) => {
        if (data.user.newsletter) subscribeToNewsletter(data.user.email)

        props.userStore.setUser(data.user)
        props.userStore.setJWT(data.jwt)
      })
      .catch((error: any) => {
        const errorId = get(error, 'response.data.message[0].messages[0].id', null)

        if (errorId === 'Auth.form.error.email.taken') {
          setError('Un compte existe déjà avec cette adresse email')
        } else {
          setError('Une erreur est survenue, merci de réessayer plus tard')
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  return (
    <Wrapper className={props.className}>
      <MailchimpSubscribe
        url={MAILCHIMP_NEWSLETTER_FORM_URL}
        render={({ subscribe }: { subscribe: any }) => {
          return (
            <Signup
              onSubmit={(values, actions) =>
                onSubmit(values, actions, (email: string) => subscribe({ EMAIL: email }))
              }
            />
          )
        }}
      />

      <FormError>{error}</FormError>
    </Wrapper>
  )
}

export default inject(`userStore`)(observer(SignupForm))
