import React from 'react'
import styled, { css } from 'styled-components'

import { onEvent } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'

const ButtonNext = css`
  right: 0px;

  svg {
    transform: rotate(180deg);
  }
`

const ButtonPrev = css`
  left: 0px;
`

const Button = styled.button<{ direction: string }>`
  position: absolute;
  display: flex;
  padding: 12px;
  top: 0;
  background: none;
  border: none;
  outline: none;
  color: ${COLORS.white};

  ${(props: { direction: string }) => (props.direction === 'prev' ? ButtonPrev : ButtonNext)}

  ${onEvent`
    color: ${COLORS.brand};
  `}
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ClassesDateSliderArrowNext({ className, ...props }: any) {
  return (
    <Button {...props} direction="next">
      <svg width={48} height={18}>
        <path d="M47.3 8H4.6l7.1-6.3L10.3.3.5 9l9.8 8.7 1.4-1.4L4.6 10h42.7z" fill="currentColor" />
      </svg>
    </Button>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ClassesDateSliderArrowPrev({ className, ...props }: any) {
  return (
    <Button {...props} direction="prev">
      <svg width={48} height={18}>
        <path d="M47.3 8H4.6l7.1-6.3L10.3.3.5 9l9.8 8.7 1.4-1.4L4.6 10h42.7z" fill="currentColor" />
      </svg>
    </Button>
  )
}
