export const API_BASE_URL = process.env.GATSBY_API_URL

export const API_ME_URL = `${API_BASE_URL}/users/me`
export const API_LOGIN_URL = `${API_BASE_URL}/auth/local`
export const API_SIGNUP_URL = `${API_BASE_URL}/auth/local/register`
export const API_FORGOT_PASSWORD_URL = `${API_BASE_URL}/auth/forgot-password`
export const API_RESET_PASSWORD_URL = `${API_BASE_URL}/auth/reset-password`

export const API_BOOK = `${API_BASE_URL}/user/book`
export const API_CANCEL = `${API_BASE_URL}/user/cancel`
export const API_BUY_PACK = `${API_BASE_URL}/user/buy`
export const API_PACKS_ALL = `${API_BASE_URL}/user/packs`
export const API_SESSIONS_ALL = `${API_BASE_URL}/user/sessions`
export const API_CLASSES_ALL = `${API_BASE_URL}/user/classes`
export const API_CLASSES_ONE = `${API_BASE_URL}/user/classes/:id`
export const API_CREDITS_ALL = `${API_BASE_URL}/user/credits`
export const API_CHANGE_PASSWORD_URL = `${API_BASE_URL}/user/change-password`

export const DEFAULT_TTL = 60 // in minutes
