import { inject, observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import SessionItem from '../components/presentationals/atoms/SessionItem/SessionItem'
import SessionList from '../components/presentationals/atoms/SessionList/SessionList'
import { MOMENT_DAY_AND_DATE_FORMAT } from '../constants/datetime'
import ISession from '../types/Entities/ISession'
import { formatDate, formatTime } from '../utils/date'

const Empty = styled.div``

interface IProps {
  sessionsStore?: any
  handleSessionCancellation?: (session: ISession) => void
}

const NextSessions = ({ sessionsStore, handleSessionCancellation }: IProps) => {
  if (sessionsStore.isLoading) {
    return <div>Chargement...</div>
  }

  if (!sessionsStore.nextSessions.length) {
    return <Empty>Aucune autre session réservée pour le moment.</Empty>
  }

  return (
    <SessionList>
      {sessionsStore.nextSessions.map((session: ISession) => {
        const cancel = handleSessionCancellation
          ? () => handleSessionCancellation(session)
          : undefined

        return (
          <SessionItem
            handleSessionCancellation={cancel}
            key={`NextSessions_${session.id}`}
            date={formatDate(session.class.date, MOMENT_DAY_AND_DATE_FORMAT)}
            coach={session.class.coaches.map(c => c.name).join(', ')}
            title={session.class.title}
            time={formatTime(session.class.time)}
            duration={`${session.class.duration} min`}
            bike={session.bike}
          />
        )
      })}
    </SessionList>
  )
}

export default inject(`sessionsStore`)(observer(NextSessions))
