import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS } from '../../../../assets/styles/variables'

interface IProps {
  className?: string
  links: string[]
}

const Wrapper = styled.section`
  margin: 60px auto;

  ${media.md`
      margin: 100px auto;
  `}

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    color: ${COLORS.white};
    font-family: ${FONTS.brand};
    font-weight: 500;
    font-size: 1rem;
  }

  li {
    padding: 10px 0;
    width: 100%;

    ${media.md`
      padding: 14px 15px;
      width: 50%;
  `};
  }
`

const SectionMusic: FC<IProps> = ({ className, links }) => (
  <Wrapper className={className}>
    <ul>
      {links.map((link, index) => (
        <li key={`music_${index}`}>
          <iframe
            title={`music_${index}`}
            src={link}
            width="100%"
            height="80"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </li>
      ))}
    </ul>
  </Wrapper>
)

export default SectionMusic
