import React from 'react';

function ArrowBack(props) {
  return (
    <svg width={48} height={18} viewBox="0 0 48 18" {...props}>
      <path d="M47.3 8H4.6l7.1-6.3L10.3.3.5 9l9.8 8.7 1.4-1.4L4.6 10h42.7z" fill="currentColor" />
    </svg>
  )
}

export default ArrowBack
