import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { media } from '../../../../assets/styles/mixins'
import { Heading1, Heading2 } from '../../tokens/Font/Font'

import { COLORS, FONTS } from '../../../../assets/styles/variables'

interface BlockTextProps {
  className?: string
  title?: string | React.ReactNode
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  text?: string
  styles?: any
}

const Children = styled.div`
  margin-top: 60px;
`

const Title = styled.h2``

const Wrapper = styled.div`
  h1 {
    ${Heading2}
    margin: 20px 0;

    ${media.md`
      ${Heading1};
      margin-bottom: 20px;
    `}
  }

  h2 {
    font-family: ${FONTS.brand};
    font-size: 2.375rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    font-family: ${FONTS.sansSerif};
    font-size: 1.125rem;
    margin-bottom: 20px;
    line-height: 1.78;

    a {
      text-decoration: none;
      color: ${COLORS.white};
      vertical-align: middle;

      :hover {
        text-decoration: none;
        color: ${COLORS.brand};
      }
    }
  }
`

const Text = styled.div`
  ul {
    padding-left: 40px;
    margin-top: 10px;
  }

  li {
    list-style: disc;
  }
`

const BlockText: FC<BlockTextProps> = ({
  className,
  styles,
  title,
  titleTag = 'h2',
  text,
  children
}) => (
  <Wrapper className={className} styles={styles}>
    {title && <Title as={titleTag}>{title}</Title>}
    {text && (
      <Text>
        <ReactMarkdown source={text} />
      </Text>
    )}
    {children && <Children>{children}</Children>}
  </Wrapper>
)

export default BlockText
