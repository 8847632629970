import React from 'react'
import styled from 'styled-components'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import Page from '../layout/Page'
import { COLORS } from '../assets/styles/variables'
import { media } from '../assets/styles/mixins'
import PasswordResetFormContainer from '../containers/PasswordResetForm'

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
  padding: 60px 0;
  ${media.md`
    padding-bottom: 100px;
  `}
`

export default function PasswordReset() {
  return (
    <Page>
      <Container>
        <Title>Réinitialisation de votre mot de passe</Title>
      </Container>
      <ContainerWhiteBackground fluid>
        <PasswordResetFormContainer />
      </ContainerWhiteBackground>
    </Page>
  )
}
