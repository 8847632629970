import React, { FC } from 'react';
import styled from 'styled-components';

import { media } from '../../../../assets/styles/mixins';

const FluidWrapper = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 1px 25px;

  ${media.md`
    padding: 1px 16px;
  `}
`

interface ContainerProps {
  className?: string
  children: React.ReactNode
  fluid?: boolean
}

const Container: FC<ContainerProps> = ({ children, className, fluid }) => {
  if (fluid) {
    return (
      <FluidWrapper className={className}>
        <Wrapper>{children}</Wrapper>
      </FluidWrapper>
    )
  }
  return <Wrapper>{children}</Wrapper>
}

export default Container
