import React, { FC } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import 'normalize.css'

import Footer from '../components/presentationals/organisms/Footer/Footer'
import Header from '../components/presentationals/organisms/Header/Header'
import Seo from '../containers/Seo'
import useSiteMetadata from '../hooks/build/useSiteMetadata'
import useUserStore from '../hooks/runtime/useUserStore'
import initYup from '../utils/initYup'
import GlobalStyle from './../assets/styles/normalize'

/**
 * Init
 */
initYup()

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`

const Content = styled.div`
  margin-top: 0;

  @media (max-width: 1120px) {
    margin-top: 70px;
  }
`

interface PageProps {
  className?: string
  children: React.ReactNode
}

const Page: FC<PageProps> = ({ children }) => {
  const { contact } = useSiteMetadata()
  const userStore = useUserStore()

  if (userStore.needToFetchUser) {
    userStore.fetchUser()
  }

  return (
    <LayoutRoot>
      <GlobalStyle />
      <Helmet>
        <html lang="fr" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Seo />
      <Header />
      <Content>{children}</Content>
      <Footer facebook={contact.facebook} instagram={contact.instagram} />
    </LayoutRoot>
  )
}

export default Page
