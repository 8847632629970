import { action, computed, decorate, observable, runInAction } from 'mobx'
import moment, { Moment } from 'moment'

import fetchAll from '../../api/packs/fetchAll'
import IPack from '../../types/Entities/IPack'
import { ILoadingState, isLoading } from '../../types/ILoadingState'

export class PacksModel {
  private refreshedAt: Moment | null = null
  public data: IPack[] | null = null
  public state: ILoadingState = ILoadingState.pending

  /**
   * Setters
   */
  private setAll(data: IPack[]) {
    this.data = data
  }

  public clearAll() {
    this.data = null
    this.refreshedAt = null
    this.state = ILoadingState.pending
  }

  /**
   * Computed values
   */
  public get isLoading() {
    return isLoading(this.state)
  }

  public get allPacks(): IPack[] {
    return this.data || []
  }

  public get discoveryPacks(): IPack[] {
    return this.allPacks.filter(pack => pack.discovery).sort((a, b) => a.credits - b.credits)
  }

  public get nonDiscoveryPacks(): IPack[] {
    return this.allPacks.filter(pack => !pack.discovery).sort((a, b) => a.credits - b.credits)
  }

  /**
   * Functions
   */
  private needToRefresh() {
    if (!this.refreshedAt) {
      return true
    }

    const reference = this.refreshedAt.clone().add(60, 'minutes')
    return reference.isBefore(moment.now())
  }

  /**
   * Async
   */
  public async fetchIfNeeded() {
    return this.needToRefresh() ? this.fetch() : Promise.resolve()
  }

  private async fetch() {
    this.state = ILoadingState.loading

    try {
      const data = await fetchAll()
      runInAction(() => {
        this.setAll(data)
        this.refreshedAt = moment()
        this.state = ILoadingState.success
      })
    } catch (error) {
      runInAction(() => {
        this.state = ILoadingState.error
        this.refreshedAt = moment()
      })
    }
  }
}

decorate(PacksModel, {
  data: observable,
  state: observable,
  // Data
  allPacks: computed,
  discoveryPacks: computed,
  nonDiscoveryPacks: computed,
  isLoading: computed,
  // Actions
  fetchIfNeeded: action
})

const PacksStore = new PacksModel()
export default PacksStore
