import GatsbyLink from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import { ButtonStyleSecondary } from '../components/presentationals/tokens/Button/Button'
import useExperience from '../hooks/build/useExperience'
import Page from '../layout/Page'

const Button = styled(GatsbyLink)`
  ${ButtonStyleSecondary};
  margin: 60px auto;
`

export default () => {
  const data = useExperience()
  return (
    <Page>
      <Container>
        <Title>{data.title}</Title>
        {data.blocks.map(block => (
          <SectionImageAndText
            key={`Experience_block_${block.id}`}
            title={block.title}
            text={block.text}
            image={block.image.childImageSharp}
            flip={block.imagePosition === 'left'}
          />
        ))}
        <Button to={data.buttonLink}>
          <span>{data.buttonLabel}</span>
        </Button>
        <SectionHashtag />
      </Container>
    </Page>
  )
}
