import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { FormikHelpers } from 'formik'

import { Heading2 } from '../components/presentationals/tokens/Font/Font'
import BaseFormError from '../components/presentationals/atoms/FormError/FormError'
import resetPassword from '../api/user/resetPassword'
import PasswordResetForm from '../components/presentationals/formik/forms/PasswordReset/PasswordReset'
import IMap from '../types/IMap'

const FormLabel = styled.h2`
  ${Heading2};

  margin-bottom: 60px;
`

const LoginHint = styled.p`
  font-size: 1.5rem;
`

const FormError = styled(BaseFormError)`
  margin-top: 16px;
`

const RESET_PASSWORD_ERRORS_ID: IMap<string> = {
  'Auth.form.error.code.provide': 'Le code fourni est invalide.',
  'Auth.form.error.password.matching': 'Les mots de passes fournis ne sont pas identiques.',
  default:
    'Une erreur est survenue lors de la réinitialisation. Veuillez contacter hello@journeystudio.fr.'
}

export interface IValues {
  password: string
  confirmPassword: string
}

export default function PasswordResetFormContainer() {
  const [code, setCode] = useState<string | null>(null)
  const [hasResetSucceeded, setHasResetSucceeded] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (!params.has('code')) {
      setError("Aucun code de réinitalisation n'à été fourni.")
      setHasResetSucceeded(true)
    }

    setCode(params.get('code'))
  }, [])

  const handleSubmit = (
    { password, confirmPassword }: IValues,
    actions: FormikHelpers<IValues>
  ) => {
    try {
      resetPassword(code as string, password, confirmPassword)
        .then(() => {
          setHasResetSucceeded(true)
          setError('')
          actions.resetForm()
          actions.validateForm()
        })
        .catch(e => {
          const id = get(e, 'response.data.message[0].messages[0].id', 'default')
          setError(RESET_PASSWORD_ERRORS_ID[id])
          setHasResetSucceeded(false)
        })
        .finally(() => actions.setSubmitting(false))
    } catch {}
  }

  // No 'code' query param present
  if (hasResetSucceeded && error) {
    return (
      <>
        <FormLabel>Impossible de réinitialiser le mot de passe</FormLabel>
        <FormError>{error}</FormError>
      </>
    )
  }

  return (
    <>
      <FormLabel>
        {hasResetSucceeded
          ? 'Réinitialisation réussie'
          : 'Veuillez renseigner votre nouveau mot de passe'}
      </FormLabel>

      {hasResetSucceeded ? (
        <LoginHint>
          Vous pouvez maintenant fermer cette page et utiliser votre nouveau mot de passe pour vous
          connecter.
        </LoginHint>
      ) : (
        <PasswordResetForm onSubmit={handleSubmit} />
      )}

      {error && <FormError>{error}</FormError>}
    </>
  )
}
