import React from "react"

function ArrowSliderBack(props) {
  return (
    <svg width={50} height={30} viewBox="0 0 50 30" {...props}>
      <path stroke="currentColor" d="M6.6.9L.9 29.5h42.5L49 .9z" />
      <path
        fill="currentColor"
        d="M35.7 14H16.8l5-4.5L20.5 8l-7.9 7 7.9 7 1.3-1.5-5-4.5h18.9z"
      />
    </svg>
  )
}

export default ArrowSliderBack
