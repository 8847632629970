import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import styled from 'styled-components'
import React from 'react'

import Button from '../../../atoms/Button/Button'
import TextField from '../../fields/TextField/TextField'
import validationSchema from './PasswordReset.validationSchema'
import { media } from '../../../../../assets/styles/mixins'

const INITIAL_VALUES = {
  password: '',
  confirmPassword: ''
}

const Wrapper = styled.div`
  ${media.md`
    max-width: 50%;
  `}
`

interface IProps {
  className?: string
  onSubmit: (values: IValues, action: FormikHelpers<IValues>) => void
}

export interface IValues {
  password: string
  confirmPassword: string
}

export default function PasswordResetForm({ onSubmit, className }: IProps) {
  return (
    <Wrapper>
      <Formik
        validateOnMount
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, handleSubmit }: FormikProps<IValues>) => {
          return (
            <Form className={className} onSubmit={handleSubmit}>
              <Field
                fullWidth
                required
                name="password"
                label="Nouveau mot de passe"
                component={TextField}
                type="password"
                helperText="8 caractères minimum dont au moins 1 majuscule et 1 caractère spécial"
              />
              <Field
                fullWidth
                required
                name="confirmPassword"
                label="Répéter le mot de passe"
                component={TextField}
                type="password"
              />
              <Button type="submit" disabled={isSubmitting || !isValid} label="Mettre à jour" />
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}
