import { FormikHelpers } from 'formik'
import get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import login, { IReturn as ILogin } from '../api/user/login'
import BaseFormError from '../components/presentationals/atoms/FormError/FormError'
import Button from '../components/presentationals/atoms/Button/Button'
import Login, {
  IValues as ILoginValues
} from '../components/presentationals/formik/forms/Login/Login'
import IMap from '../types/IMap'
import ForgottenPasswordModal from '../components/presentationals/organisms/ForgottenPasswordModal/ForgottenPasswordModal'
import forgottenPassword from '../api/user/forgottenPassword'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FormError = styled(BaseFormError)`
  margin-top: 16px;
`

const ForgotPasswordButton = styled(Button)`
  padding: 0 !important;
`
interface IProps {
  userStore?: any // @todo
  creditsStore?: any
  className?: string
}

const LOGIN_ERRORS_ID: IMap<string> = {
  'Auth.form.error.confirmed': 'Vous devez valider votre adresse email, allez voir vos emails.',
  'Auth.form.error.invalid': 'Identifiants incorrects.',
  default:
    'Une erreur est survenue lors de la connexion. Si cette erreur persiste, veuillez contacter hello@journeystudio.fr.'
}

const FORGOT_PASSWORD_ERRORS_ID: IMap<string> = {
  'Auth.form.error.user.not-exist': "L'adresse e-mail fournie ne correspond à aucun compte.",
  default:
    "Une erreur est survenue lors de l'envoie de l'email. Veuillez contacter hello@journeystudio.fr."
}

function LoginForm(props: IProps) {
  const [loginError, setLoginError] = useState('')

  const [forgotPasswordError, setForgotPasswordError] = useState('')
  const [passwordForgotten, setPasswordForgotten] = useState(false)
  const [isSendingPending, setIsSendingPending] = useState(false)
  const [hasSendingSuceeded, setHasSendingSuceeded] = useState(false)

  const handleModalClosing = useCallback(() => {
    setForgotPasswordError('')
    setPasswordForgotten(false)
    setHasSendingSuceeded(false)
    setIsSendingPending(false)
  }, [hasSendingSuceeded])

  const handleModalValidate = useCallback(
    ({ email }: { email: string }, actions: FormikHelpers<{ email: string }>) => {
      setIsSendingPending(true)
      setForgotPasswordError('')

      forgottenPassword(email)
        .then(() => {
          setHasSendingSuceeded(true)
          setIsSendingPending(false)
        })
        .catch((error: any) => {
          const id = get(error, 'response.data.message[0].messages[0].id', 'default')
          setForgotPasswordError(FORGOT_PASSWORD_ERRORS_ID[id])
        })
        .finally(() => {
          setIsSendingPending(false)
          actions.setSubmitting(false)
        })
    },
    []
  )

  const onSubmit = (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => {
    login(values)
      .then((data: ILogin) => {
        props.userStore.setUser(data.user)
        props.userStore.setJWT(data.jwt)

        // force credits refetch when user logs in
        props.creditsStore.setShouldRefresh()
      })
      .catch((error: any) => {
        const id = get(error, 'response.data.message[0].messages[0].id', 'default')
        setLoginError(LOGIN_ERRORS_ID[id])
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  return (
    <Wrapper className={props.className}>
      {passwordForgotten && (
        <ForgottenPasswordModal
          handleCancel={handleModalClosing}
          handleValidate={handleModalValidate}
          isSendingPending={isSendingPending}
          hasSendingSuceeded={hasSendingSuceeded}
          sendingError={forgotPasswordError}
        />
      )}

      <Login onSubmit={onSubmit} />
      <FormError>{loginError}</FormError>
      <ForgotPasswordButton
        label="Mot de passe oublié"
        onClick={() => setPasswordForgotten(true)}
        className="link-light-bg"
      />
    </Wrapper>
  )
}

export default inject(`userStore`, `creditsStore`)(observer(LoginForm))
