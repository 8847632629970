import GatsbyLink from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import SectionText from '../components/presentationals/organisms/SectionText/SectionText'
import { ButtonStylePrimary } from '../components/presentationals/tokens/Button/Button'
import useFirstSession from '../hooks/build/useFirstSession'
import Page from '../layout/Page'

const Button = styled(GatsbyLink)`
  ${ButtonStylePrimary};
  margin: 60px auto;
`

export default () => {
  const data = useFirstSession()
  return (
    <Page>
      <Container>
        <Title>{data.title}</Title>
        {data.blocks.map(block =>
          block.image ? (
            <SectionImageAndText
              key={`Experience_block_${block.id}`}
              title={block.title}
              text={block.text}
              image={block.image.childImageSharp}
              flip={block.imagePosition === 'left'}
            />
          ) : (
            <SectionText title={block.title} text={block.text} />
          )
        )}
        <Button to={data.buttonLink}>
          <span>{data.buttonLabel}</span>
        </Button>
        <SectionHashtag />
      </Container>
    </Page>
  )
}
