import { graphql, useStaticQuery } from 'gatsby'

import ICgv from '../../types/Entities/ICgv'

const useCgv = (): ICgv => {
  const data = useStaticQuery(
    graphql`
      query Cgv {
        strapiCgv {
          title
          text
        }
      }
    `
  )

  return data.strapiCgv
}

export default useCgv
