import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { FONTS } from '../../../../assets/styles/variables'

interface IProps {
  className?: string
  title?: string | React.ReactNode
  text?: string | React.ReactNode
}

const Wrapper = styled.section`
  width: 100%;
`

const Text = styled.p`
  font-family: ${FONTS.brand};
  font-size: 1.8rem;
  margin: 20px auto;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  max-width: 770px;
  line-break: break-words;

  ${media.md`
    font-size: 2.875rem;  
  `}
`

const SectionTextHightlight: FC<IProps> = ({ className, text, children }) => (
  <Wrapper className={className}>
    <Text>{text}</Text>
    {children}
  </Wrapper>
)

export default SectionTextHightlight
