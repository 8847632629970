import { action, computed, decorate, observable, runInAction } from 'mobx'
import moment, { Moment } from 'moment'

import refreshUser from '../../api/user/refreshUser'
import IUser from '../../types/Entities/IUser'
import { ILoadingState } from '../../types/ILoadingState'
import storage from '../../utils/storage'

export class UserModel {
  refreshedAt: Moment | null = null
  user: IUser | null = null
  state: ILoadingState = ILoadingState.pending
  jwt: string | null = storage.getJWTToken()

  /**
   * Setters
   */
  setUser(user: IUser) {
    this.user = {
      ...user,
      birthdate: moment(user.birthdate)
    }
  }

  setJWT(token: string | null) {
    this.jwt = token
    storage.setJWTToken(token)
  }

  /**
   * Computed values
   */
  get isLoggedIn() {
    return !!this.user
  }

  get needToFetchUser() {
    return this.state === ILoadingState.pending && this.needToRefreshUser
  }

  get isLoading() {
    return this.state === ILoadingState.pending || this.state === ILoadingState.loading
  }

  /**
   * Functions
   */
  needToRefreshUser() {
    if (!this.refreshedAt) {
      return true
    }

    // @TODO check if expiration JWT time mecanism already exits
    const reference = this.refreshedAt.clone().add(60, 'minutes')
    return reference.isBefore(moment.now())
  }

  logoutUser = () => {
    this.user = null
    this.setJWT(null)
    this.refreshedAt = moment()
    this.state = ILoadingState.success
  }

  /**
   * Async
   */
  async fetchUser() {
    this.state = ILoadingState.loading

    try {
      // User is not set or we need to refresh it
      if (!this.user || this.needToRefreshUser) {
        if (!this.jwt) {
          // Abort if no JWT
          this.state = ILoadingState.error
          this.user = null
        } else {
          const { user, jwt } = await refreshUser(this.jwt)
          runInAction(() => {
            this.user = user
            this.setJWT(jwt)
            this.refreshedAt = moment()
            this.state = ILoadingState.success
          })
        }
      }
    } catch (error) {
      runInAction(() => {
        this.state = ILoadingState.error
        this.refreshedAt = moment()
        this.user = null
      })
    }
  }
}

decorate(UserModel, {
  // Models
  user: observable,
  state: observable,
  // Methods
  setUser: action,
  setJWT: action,
  // Helper methods
  isLoggedIn: computed,
  isLoading: computed,
  needToFetchUser: computed
})
const UserStore = new UserModel()
export default UserStore
