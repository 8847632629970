import GatsbyLink from 'gatsby-link'
import React, { FC } from 'react'
import styled from 'styled-components'

import {
  ROUTE_ACCOUNT_PACKS,
  ROUTE_ACCOUNT_PROFILE,
  ROUTE_ACCOUNT_SESSIONS,
  ROUTE_ACCOUNT_SUM_UP
} from '../../../..//constants/routes'
import { media } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'
import { ButtonReset } from '../../../../components/presentationals/tokens/Button/Button'
import { Body1, Heading2, Heading4, Heading5 } from '../../../../components/presentationals/tokens/Font/Font'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  margin-bottom: 45px;

  ${media.md`
    width: 270px;
    margin: 0
  `}
`

const NavWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color: ${COLORS.black};

  ${media.md`
    overflow-x: unset;
  `}
`

const Nav = styled.ul`
  width: max-content;
`

const Username = styled.h3`
  ${Heading4};
  padding: 0;
  margin: 0 0 20px 0;

  ${media.md`
    ${Heading2};
    margin: 0 0 30px 0;
  `}
`

const NavItem = styled.li`
  display: inline-block;
  margin-right: 20px;

  ${media.md`
    display: inherit;
    margin-right: 0;
  `}
`

const NavItemLogout = styled.button`
  ${ButtonReset};
  ${Heading5};

  :hover {
    color: ${COLORS.brand};
  }

  ${media.md`
    ${Body1};
    line-height: 5;
  `};
`

const NavItemLink = styled(GatsbyLink)`
  ${Heading5};
  position: relative;

  ${media.md`
    ${Heading4};
    line-height: 2;
  `};

  &.active,
  :focus,
  :hover {
    text-decoration: none;
    color: ${COLORS.brand};
  }

  &.active:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${COLORS.brand};
  }
`

interface IProps {
  className?: string
  username: string
  onLogoutClick: () => void
}

const Navigation: FC<IProps> = ({ className, username, onLogoutClick }) => (
  <Wrapper className={className}>
    <Username>{username}</Username>
    <NavWrapper>
      <Nav>
        <NavItem>
          <NavItemLink activeClassName="active" to={ROUTE_ACCOUNT_SUM_UP}>
            Résumé
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink activeClassName="active" to={ROUTE_ACCOUNT_PACKS}>
            Crédits
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink activeClassName="active" to={ROUTE_ACCOUNT_SESSIONS}>
            Sessions passées
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLink activeClassName="active" to={ROUTE_ACCOUNT_PROFILE}>
            Profil
          </NavItemLink>
        </NavItem>
        <NavItem>
          <NavItemLogout onClick={onLogoutClick}>Me déconnecter</NavItemLogout>
        </NavItem>
      </Nav>
    </NavWrapper>
  </Wrapper>
)

export default Navigation
