/* eslint-disable no-template-curly-in-string */
import { addMethod, setLocale, string } from 'yup'

/**
 * Message translations
 */
const YUP_LOCALE = {
  mixed: {
    required: 'Ce champ est obligatoire.'
  },
  string: {
    email: "Ceci n'est pas une adresse email valide.",
    required: 'Ce champ est obligatoire.',
    min: 'Le texte doit avoir une longueur minimum de ${min} caractères.',
    max: 'Le texte doit avoir une longueur maximale de ${max} caractères.'
  },
  array: {
    min: '${min} sélections minimumales sont attendues.',
    max: '${max} sélections maximales sont attendues.'
  },
  number: {
    integer: 'Le numéro ne doit comporter que des chiffres.'
  }
}

/**
 * Custom Validator
 */
function password(msg: string): any {
  return this.test({
    name: 'password',
    message: msg || 'Doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre.',
    test: (value = '') => /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value)
  })
}

function phone(msg: any) {
  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
  return this.test({
    name: 'phone',
    message: msg || 'Ce champs doit être un numéro de téléphone.',
    test: (value?: string) => !value || value.match(phoneRegExp)
  })
}

function equalTo(ref: any, msg: any) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} doit correspondre à ${reference}',
    params: {
      reference: ref.path
    },
    test(value: any) {
      return value === this.resolve(ref)
    }
  })
}

/**
 * Init
 */

export default () => {
  setLocale(YUP_LOCALE)
  addMethod(string, 'password', password)
  addMethod(string, 'phone', phone)
  addMethod(string, 'equalTo', equalTo)
}
