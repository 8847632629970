import { css } from 'styled-components'

import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'

export const ButtonReset = css`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
  line-height: 1;
  width: max-content;
  height: max-content;

  &:hover {
    text-decoration: none;
  }
`

export const ButtonBase = css`
  display: flex;
  text-align: center;
  font-size: 1rem;
  font-family: ${FONTS.sansSerif};
  transition: ${TRANSITIONS.base};
`

export const ButtonPadding = css`
  padding: 14px 30px;
`

export const ButtonBorder = css`
  border-width: 1px;
  border-style: solid;
`
export const ButtonSkrew = css`
  -ms-transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);

  span {
    -ms-transform: skewX(15deg);
    -webkit-transform: skewX(15deg);
    transform: skewX(15deg);
    margin: auto;
  }
`

export const ButtonFluid = css`
  width: 100% !important;
  justify-content: center;
`

export const ButtonStylePrimary = css`
  ${ButtonReset};
  ${ButtonBase};
  ${ButtonPadding};
  ${ButtonSkrew};
  ${ButtonBorder};

  font-weight: bold;
  background-color: ${COLORS.brand};
  border-color: ${COLORS.brand};
  color: ${COLORS.white};

  &:hover {
    background-color: ${COLORS.lightRed};
    border-color: ${COLORS.lightRed};
  }

  &:disabled {
    background-color: ${COLORS.ice};
    border-color: ${COLORS.ice};
    color: ${COLORS.brownGrey};
  }
`

export const ButtonStyleSecondary = css`
  ${ButtonReset};
  ${ButtonBase};
  ${ButtonPadding};
  ${ButtonSkrew};
  ${ButtonBorder};

  font-weight: bold;
  background-color: transparent;
  border-color: ${COLORS.brownGrey};
  color: ${COLORS.white};

  &:hover {
    background-color: ${COLORS.brand};
    border-color: ${COLORS.brand};
    color: ${COLORS.white};
  }

  &:disabled {
    background-color: ${COLORS.ice};
    border-color: ${COLORS.ice};
    color: ${COLORS.brownGrey};
  }
`

export const ButtonStyleTertiary = css`
  ${ButtonReset};
  ${ButtonBase};
  ${ButtonPadding};
  ${ButtonSkrew};
  ${ButtonBorder};

  font-weight: bold;
  background-color: transparent;
  border-color: ${COLORS.brownGrey};
  color: ${COLORS.brownGrey};

  &:hover {
    background-color: ${COLORS.brand};
    border-color: ${COLORS.brand};
    color: ${COLORS.white};
  }

  &:disabled {
    background-color: ${COLORS.ice};
    border-color: ${COLORS.ice};
    color: ${COLORS.brownGrey};
  }
`
