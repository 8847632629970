import { Switch } from '@material-ui/core'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { navigateTo } from 'gatsby'
import GatsbyLink from 'gatsby-link'
import React from 'react'
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider,
} from 'react-stripe-elements'
import styled, { createGlobalStyle } from 'styled-components'

import buyPack from '../../../../api/stripe/buyPack'
import { COLORS, FONTS } from '../../../../assets/styles/variables'
import { ROUTE_CGV, ROUTE_SESSION_SELECT } from '../../../../constants/routes'
import CreditsStore from '../../../../store/models/credits'
import IPack from '../../../../types/Entities/IPack'
import Button from '../../atoms/Button/Button'
import { LinkFont, LinkLightBg } from '../../tokens/Link/Link'
import StripeScriptLoader from '../StripeScriptLoader'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.brand,
    },
  },
})
const Form = styled.form`
  & > *:last-child {
    margin-top: 30px;
  }
`

const CGV = styled.div`
  margin: 16px 0;
  font-size: 0.875rem;
`

const Link = styled(GatsbyLink)`
  ${LinkFont};
  ${LinkLightBg};
`

const STYLES = {
  base: {
    fontSize: '16px',
    color: '#424770',
    fontFamily: FONTS.sansSerif,
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  invalid: {
    color: '#9e2146',
  },
}

interface Props {
  pack: IPack
  stripe: any //StripeProps
  elements: any // StripeElement
}

class _CardForm extends React.Component<Props> {
  state = {
    disabled: true,
    completed: false,
    error: undefined,
    submitting: false,
    cgv: false,
  }

  handleChange = (change: any) => {
    this.setState({ disabled: !change.complete })
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()
    this.setState({ submitting: true })

    const { stripe, elements, pack } = this.props

    if (stripe) {
      const clientSecret = await buyPack(pack.id)

      // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment

      await stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement('card'),
          },
        })
        .then((r: { error?: { message: string } }) => {
          this.setState(() => ({ submitting: false }))

          if (r.error) {
            this.setState(() => ({ error: r.error?.message }))
          } else {
            this.setState(() => ({ completed: true }))
            CreditsStore.setShouldRefresh()
          }
        })
        .catch(() => {
          this.setState(() => ({
            error:
              "Quelque chose s'est mal passé sur nos serveurs, veuillez réessayer ultérieurement."
          }))
        })
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }

  handleCGVChange = (event: any) => {
    this.setState({ cgv: event.target.checked })
  }

  render() {
    const { submitting, completed, error, disabled, cgv } = this.state

    if (typeof error !== 'undefined') {
      return (
        <div>
          <h2>Oups, quelque chose s'est mal passé.</h2>
          {error && <p>{error}</p>}
          <Button
            label="Réessayer"
            onClick={() => this.setState({ error: undefined, cgv: false })}
          />
        </div>
      )
    }

    if (completed) {
      return (
        <div>
          <h2>Félicitation, votre paiement est validé.</h2>
          <p>
            Vous pouvez à présent utiliser vos crédits pour réserver une
            session.
          </p>
          <Button
            label="Reserver une session"
            onClick={() => navigateTo(ROUTE_SESSION_SELECT)}
          />
        </div>
      )
    }

    const label = submitting
      ? 'Veuillez patientez ...'
      : `Acheter le ${this.props.pack.name}`

    return (
      <Form onSubmit={this.handleSubmit}>
        <CardElement onChange={this.handleChange} style={STYLES} />
        <ThemeProvider theme={theme}>
          <CGV>
            <Switch
              name="cgv"
              checked={cgv}
              onChange={this.handleCGVChange}
              color="primary"
            />
            J’ai lu et j’accepte les&nbsp;
            <Link to={ROUTE_CGV}>conditions générales de vente</Link>
          </CGV>
        </ThemeProvider>
        <Button
          label={label}
          disabled={!cgv || disabled || submitting}
          type="submit"
        />
      </Form>
    )
  }
}

const CardForm = injectStripe(_CardForm)

const Styles = createGlobalStyle`

  .StripeElement {
    box-sizing: border-box;
    padding: 15px 12px;
    font-size: 16px;
    border: 1px solid ${COLORS.brownGrey};
    background-color: white;
  }

  .StripeElement--focus {
    borderColor: ${COLORS.brownGrey};
    backgroundColor: ${COLORS.ice};
  }

  .StripeElement--invalid {
    border-color: ${COLORS.lightRed};
  }

  .StripeElement--webkit-autofill {
    background-color: ${COLORS.ice} !important;
  }
`

export default ({ pack, stripeKey }: { pack: IPack; stripeKey: string }) => (
  <>
    <Styles />
    <StripeScriptLoader
      uniqueId="stripeScript"
      script="https://js.stripe.com/v3/"
      loader="Loading..."
    >
      <StripeProvider apiKey={stripeKey}>
        <Elements>
          <CardForm pack={pack} />
        </Elements>
      </StripeProvider>
    </StripeScriptLoader>
  </>
)
