import React, { FC } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../../assets/styles/variables'
import Button from '../../atoms/Button/Button'
import { Body2, Heading3, Heading4 } from '../../tokens/Font/Font'

interface IProps {
  as?: 'div' | 'li'
  className?: string
  title: string
  subtitle: string
  description: string
  buttonLabel: string
  onButtonClicked: () => void
  disableButton?: boolean
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 30px 40px 30px;
  width: 100%;
  max-width: 370px;
  height: 350px;
  color: ${COLORS.black};
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.gray.dark};

  :hover {
    background-color: ${COLORS.ice};
  }
`

const Content = styled.div``
const Buttons = styled.div`
  justify-content: center;
  display: flex;
`

const Title = styled.h3`
  ${Heading3}
  margin-bottom: 25px;
`

const SubTitle = styled.h4`
  ${Heading4}
  margin-bottom: 10px;
`

const Description = styled.p`
  ${Body2}
`

const Pack: FC<IProps> = ({
  as = 'li',
  className,
  title,
  subtitle,
  description,
  buttonLabel,
  onButtonClicked,
  disableButton = false
}) => (
  <Wrapper className={className} as={as}>
    <Content>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Description>{description}</Description>
    </Content>
    <Buttons>
      <Button disabled={disableButton} onClick={onButtonClicked} label={buttonLabel} />
    </Buttons>
  </Wrapper>
)

export default Pack
