import GatsbyImage from 'gatsby-image'
import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import BlockText from '../../molecules/BlockText/BlockText'

interface SectionImageAndTextProps {
  id?: string
  className?: string
  title?: string | React.ReactNode
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  text?: string | React.ReactNode
  flip?: boolean
  image?: any
}

const Wrapper = styled.section<Partial<SectionImageAndTextProps>>`
  display: flex;
  flex-direction: column;
  padding: 60px 0px;

  ${media.md`
    flex-direction: ${(props: Partial<SectionImageAndTextProps>) =>
      props.flip ? 'row-reverse' : 'row'};
  `}
`

const Image = styled(GatsbyImage)`
  display: flex;
  width: 100%;

  ${media.md`
    flex: 1;
    max-width: 33%;
  `}
`

const StyledBlockText = styled(BlockText)`
  margin-bottom: 60px;

  ${media.md`
    margin-bottom: 0px;
    padding: 70px 30px 0 30px;
    flex: 1;
    max-width: 66%;

    padding: ${(props: any) => (props.flip ? '70px 0px 0 30px' : '70px 30px 0 0px')}
  `}
`

const SectionImageAndText: FC<SectionImageAndTextProps> = ({
  id,
  className,
  title,
  titleTag,
  text,
  children,
  image,
  flip = false
}) => (
  <Wrapper className={className} id={id} flip={flip}>
    <StyledBlockText title={title} titleTag={titleTag} text={text} flip={flip}>
      {children}
    </StyledBlockText>
    {image && <Image {...image} />}
  </Wrapper>
)

export default SectionImageAndText
