import Link from 'gatsby-link';
import React, { FC } from 'react';
import styled from 'styled-components';

import { FONTS, TRANSITIONS } from '../../../../assets/styles/variables';
import ArrowBack from '../Icon/ArrowBack';

interface IProps {
  className?: string
  label: React.ReactNode
  to: string
}

const Wrapper = styled(Link)`
  display: flex;
  font-size: 1rem;
  font-family: ${FONTS.sansSerif};
  font-weight: 500;
  color: inherit;
  margin: 0;
  padding: 0;
  align-items: center;
  transition: ${TRANSITIONS.base};
  height: 20px;
  color: currentColor;

  :hover {
    opacity: 0.6;
    text-decoration: none;
  }

  span {
    margin-left: 20px;
  }
`

const Back: FC<IProps> = ({ className, label, to }) => (
  <Wrapper className={className} label={label} to={to}>
    <ArrowBack />
    <span>{label}</span>
  </Wrapper>
)

export default Back
