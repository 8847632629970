import axios from 'axios'

import { API_CANCEL } from '../../constants/api'
import UserStore from '../../store/models/user'
import ISession from '../../types/Entities/ISession'

const cancel = async (sessionId: string): Promise<ISession> => {
  const response = await axios({
    method: 'POST',
    url: API_CANCEL,
    data: {
      sessionId
    },
    headers: { Authorization: `Bearer ${UserStore.jwt}` }
  })

  return response.data
}

export default cancel
