import React from 'react'

import BookClassContainer from '../../containers/BookClass'
import Page from '../../layout/Page'

interface IProps {
  identifier: string
}

export default function Book({ identifier }: IProps) {
  return (
    <Page>
      <BookClassContainer identifier={identifier} />
    </Page>
  )
}
