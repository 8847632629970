import IMap from '../IMap'
import ICoach from './ICoach'

export default interface IClass {
  id: string
  title: string
  date: string
  time: string
  draft: boolean
  duration: number
  created_at: string
  updated_at: string
  coaches: ICoach[]
  bikes: IMap<boolean>
  bookable: boolean
  ready: boolean
}

export const getAvailableBikes = (selectedClass: IClass): string[] =>
  Object.entries(selectedClass.bikes || {}).reduce((acc, entry) => {
    const [key, value] = entry
    return value === false ? [...acc, key] : acc
  }, [])
