import { Switch } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';

import { COLORS } from '../../../../../assets/styles/variables';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.brand
    }
  }
})

function SwitchField(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <p>
        <Switch {...props} color="primary" /> {props.label}
      </p>
    </ThemeProvider>
  )
}

export default SwitchField
