import Link, { navigate } from 'gatsby-link'
import React, { FC } from 'react'
import styled from 'styled-components'

import { TRANSITIONS } from '../../../../assets/styles/variables'
import logo from '../../../../assets/svg/logo_journey_green.svg'
import { ROUTE_ACCOUNT_PROFILE, ROUTE_SESSION_SELECT } from '../../../../constants/routes'
import Button from '../../atoms/Button/Button'
import Navigation from '../../atoms/Navigation/Navigation'

interface HeaderDesktopProps {
  menuItems: { to: string; label: string }[]
  className?: string
}

const Wrapper = styled.header`
  max-width: 1400px;
  width: 100%;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px 0 40px;
`

const Right = styled.div`
  display: flex;

  & > * {
    margin-left: 12px !important;
  }
`

const Logo = styled.img`
  display: flex;
  width: 160px;
  transition: ${TRANSITIONS.base};

  :hover {
    opacity: 0.8;
  }
`

const HeaderDesktop: FC<HeaderDesktopProps> = ({ className, menuItems }) => (
  <Wrapper className={className}>
    <Link to="/">
      <Logo src={logo} alt="Journey Studio Cycle" />
    </Link>
    <Right>
      <Navigation menuItems={menuItems} />
      <Button
        className="secondary"
        label="Mon espace"
        onClick={() => navigate(ROUTE_ACCOUNT_PROFILE)}
      />
      <Button label="Booker une session" onClick={() => navigate(ROUTE_SESSION_SELECT)} />
    </Right>
  </Wrapper>
)

export default HeaderDesktop
