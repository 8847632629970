import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import { media } from '../../assets/styles/mixins'
import { COLORS } from '../../assets/styles/variables'
import Container from '../../components/presentationals/atoms/Container/Container'
import SideBar from '../../components/presentationals/atoms/SideBar/SideBar'
import Title from '../../components/presentationals/atoms/Title/Title'
import SectionHashtag from '../../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import Page from '../../layout/Page'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
`}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 450px;
  background: ${COLORS.white};
  color: ${COLORS.gray.dark};
  padding: 60px 25px;

  ${media.md`
    padding: 50px 100px;
  `}
`

const Layout = ({ children, userStore }: any) => (
  <Page>
    <Container>
      <Title>Mon espace Journey</Title>
      <Wrapper>
        <SideBar
          username={`${userStore.user.firstname} ${userStore.user.lastname}`}
          onLogoutClick={userStore.logoutUser}
        />
        <Content>{children}</Content>
      </Wrapper>
      <SectionHashtag />
    </Container>
  </Page>
)

export default inject(`userStore`)(observer(Layout))
