import GatsbyImage from 'gatsby-image'
import GatsbyLink from 'gatsby-link'
import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'
import { ButtonStylePrimary } from '../../tokens/Button/Button'
import { Body1, Heading3, Heading4 } from '../../tokens/Font/Font'

interface IProps {
  className?: string
  id?: string
  title: string
  date: string
  text: string
  image: any
  link?: string
  linkLabel?: string
}

const Wrapper = styled.section`
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  max-width: 1400px;

  ${media.md`
      margin: 100px auto;
  `}
`

const Image = styled(GatsbyImage)`
  display: none;
  ${media.md`
    display: block;
    width: 390px;
    object-fit: cover;
  `}
`

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${media.md`
    align-items: center;
    flex-direction: row;
  `}
`

const Content = styled.div`
  margin: 30px;
  max-width: 670px;
`
const Date = styled.h2`
  ${Heading4};
  margin-bottom: 20px;
`
const Title = styled.h1`
  ${Heading3}
  margin-bottom: 12px;
`
const Text = styled.p`
  ${Body1}
`

const InternalLink = styled(GatsbyLink)`
  ${ButtonStylePrimary}
  margin: 30px;
`

const ExternalLink = styled.a`
  ${ButtonStylePrimary}
  margin: 30px;
`

const SectionEvent: FC<IProps> = ({
  className,
  image,
  title,
  date,
  text,
  link = '/',
  linkLabel,
  id
}) => {
  const isInternal = link.startsWith('/')

  return (
    <Wrapper className={className} id={id}>
      <Image {...image} alt={title} />
      <InnerWrapper>
        <Content>
          <Date>{date}</Date>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Content>
        {linkLabel && isInternal && (
          <InternalLink to={link}>
            <span>{linkLabel}</span>
          </InternalLink>
        )}
        {linkLabel && !isInternal && (
          <ExternalLink href="" target="_blank" rel="noopener">
            <span>{linkLabel}</span>
          </ExternalLink>
        )}
      </InnerWrapper>
    </Wrapper>
  )
}

export default SectionEvent
