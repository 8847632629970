import * as yup from 'yup'

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    birthdate: yup.date().required(),
    shoes: yup.number().required(),
    phone: (yup.string() as any).phone().required(),
    password: (yup.string() as any).password().required(),
    confirmPassword: (yup.string() as any)
      .password()
      .required()
      .equalTo(yup.ref('password'), 'Vos mots de passe doivent correspondre')
  })

export default validationSchema
