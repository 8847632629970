import GatsbyImage, { GatsbyImageProps } from 'gatsby-image'
import React from 'react'
import Slick from 'react-slick'
import styled, { createGlobalStyle } from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'
import ArrowDown from '../../atoms/Icon/ArrowDown'
import ArrowSliderBack from '../../atoms/Icon/ArrowSliderBack'
import ArrowSliderNext from '../../atoms/Icon/ArrowSliderNext'

const Wrapper = styled.div`
  position: relative;
  max-width: 1400px;
  width: 100%;
  margin: auto;
`

const Slider = styled(Slick)``

const Slide = styled.div`
  content: '';
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 425px;

  ${media.sm`
    float: right;
    height: auto;
    max-width: 75%;
    width: 75%;
  `}
`
const Image = styled(GatsbyImage)`
  height: 100%;
`

const Texts = styled.div`
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 25px;
  width: 230px;

  ${media.sm`
    left: 135px;
    width: auto;
  `}

  h1 {
    font-size: 1.5rem;
    font-weight: 300;
    font-family: ${FONTS.sansSerif};
    color: ${COLORS.white};
    line-height: 1.33;
    margin: 0;
    padding: 0;
  }

  p {
    margin-top: 10px;
    font-size: 2.5rem;
    line-height: 1.15;
    font-weight: 500;
    font-family: ${FONTS.brand};
    color: ${COLORS.white};

    ${media.sm`
        font-size: 3.5rem;
    `}

    ${media.md`
        font-size: 5rem;
    `}
  }
`
const Paging = styled.div`
  content: '';
  width: 100%;
  height: 2px;
  background-color: ${COLORS.white};
`

const Override = createGlobalStyle`

  .slick-dots {
    bottom: -10px !important;
    ${media.sm`
      bottom: 45px !important;
    `}
  }

  .slick-dots li {
      width: 30px !important;
      height: 2px !important;
      margin: 0 8px !important;
      background-color:  ${COLORS.white};
      cursor: pointer;
  }
  .slick-active ${Paging} {
      background-color: ${COLORS.brand} !important;
  }
`

const Arrows = styled.div`
  display: none;
  ${media.sm`
    display: block;
  `}

  button {
    background: transparent;
    border: none;
    margin-top: 80px;
    width: 50px;
    height: 30px;
    cursor: pointer;
    color: ${COLORS.white};

    ${media.md`
      margin-top: 80px;
    `}

    ${media.lg`
      margin-top: 130px;
    `}

    :focus {
      outline: none;
    }

    :hover {
      color: ${COLORS.brand};
    }
  }
`

const ScrollDown = styled.button`
  display: none;
  outline: none;
  position: absolute;
  bottom: 40px;
  left: 0;
  padding: 0;
  background: transparent;
  border: none;
  width: 20px;
  height: 48px;
  transition: ${TRANSITIONS.base};
  cursor: pointer;

  ${media.md`
    display: inline-block;
  `}
    :hover {
    opacity: 0.5;
  }
`

interface IProps {
  title: string
  subtitle: string
  images: GatsbyImageProps[]
}

const CustomPaging = () => <Paging>&nbsp;</Paging>

export default class HeroSlider extends React.Component<IProps> {
  private slider: any

  constructor(props: any) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { title, subtitle, images } = this.props

    return (
      <Wrapper>
        <Override />
        <Slider
          ref={(c: any) => (this.slider = c)}
          customPaging={CustomPaging}
          dots={true}
          dotsClass="slick-dots"
          fade={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          autoplay={true}
          autoplaySpeed={3000}
        >
          {images.map((image, index) => (
            <div key={`Hero_${index}`}>
              <Slide>
                <Image {...image} />
              </Slide>
            </div>
          ))}
        </Slider>
        <Texts>
          <h1>{title}</h1>
          <p>{subtitle}</p>
          <Arrows>
            <button onClick={this.previous}>
              <ArrowSliderBack />
            </button>
            <button onClick={this.next}>
              <ArrowSliderNext />
            </button>
          </Arrows>
          <ScrollDown
            onClick={() => {
              const firstElement = document.getElementById('first-block')
              if (firstElement) {
                window.scrollTo({ top: firstElement.offsetTop, behavior: 'smooth' })
              }
            }}
          >
            <ArrowDown />
          </ScrollDown>
        </Texts>
      </Wrapper>
    )
  }
}
