import React from 'react';

function ArrowSliderNext(props) {
  return (
    <svg width={50} height={30} {...props}>
      <path stroke="currentColor" d="M6.3.5l-5.7 29h43.1l5.7-29z" />
      <path
        fill="currentColor"
        d="M29.6 7.7l-1.4 1.5 5.2 4.6H14.2v2h19.2l-5.2 4.5 1.4 1.5 7.9-7z"
      />
    </svg>
  )
}

export default ArrowSliderNext
