import axios from 'axios'

import { API_BOOK } from '../../constants/api'
import UserStore from '../../store/models/user'
import ISession from '../../types/Entities/ISession'

const book = async (classId: string, bike: string | number): Promise<ISession> => {
  await new Promise(r => setTimeout(r, 2000))

  const response = await axios({
    method: 'POST',
    url: API_BOOK,
    data: {
      classId,
      bike
    },
    headers: { Authorization: `Bearer ${UserStore.jwt}` }
  })

  return response.data
}

export default book
