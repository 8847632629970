import React, { FC } from 'react';
import styled from 'styled-components';

import { media } from '../../../../assets/styles/mixins';
import { COLORS } from '../../../../assets/styles/variables';

interface IProps {
  className?: string
  children: React.ReactNode
}

const Wrapper = styled.ul`
  flex-wrap: wrap;
  & > * {
    margin: 5px;
  }

  ${media.md`
    & > * {
      margin: 0;
      border-bottom: none;

      &:last-child {
        border-bottom: solid 1px ${COLORS.gray.dark};
      }
    }
  `};
`

const SessionList: FC<IProps> = ({ className, children }) => (
  <Wrapper className={className}>{children}</Wrapper>
)

export default SessionList
