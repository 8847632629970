import React, { FC } from 'react'
import styled from 'styled-components'

import {
  ButtonFluid,
  ButtonPadding,
  ButtonReset,
  ButtonStylePrimary,
  ButtonStyleSecondary,
  ButtonStyleTertiary
} from '../../tokens/Button/Button'
import { LinkDarkBg, LinkFont, LinkLightBg } from '../../tokens/Link/Link'

interface ButtonProps {
  className?: string
  label: string
  onClick?: any
  type?: 'button' | 'submit'
  disabled?: boolean
  fluid?: boolean
}

const Wrapper = styled.button<{ fluid: boolean }>`
  &.primary {
    ${ButtonStylePrimary}
  }
  &.secondary {
    ${ButtonStyleSecondary}
  }

  &.tertiary {
    ${ButtonStyleTertiary}
  }

  &.link-light-bg,
  &.link-dark-bg {
    ${ButtonReset};
    ${ButtonPadding};
    ${LinkFont};
  }

  &.link-dark-bg {
    ${LinkDarkBg}
  }

  &.link-light-bg {
    ${LinkLightBg}
  }

  ${(props: { fluid: boolean }) => props.fluid && ButtonFluid}
`

const Button: FC<ButtonProps> = ({
  label,
  onClick,
  className = 'primary',
  type = 'button',
  disabled = false,
  fluid = false
}) => (
  <Wrapper className={className} onClick={onClick} type={type} disabled={disabled} fluid={fluid}>
    <span>{label}</span>
  </Wrapper>
)

export default Button
