/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { navigateTo } from 'gatsby'
import React from 'react'

import Button from '../components/presentationals/atoms/Button/Button'
import Container from '../components/presentationals/atoms/Container/Container'
import HeroSlider from '../components/presentationals/molecules/HeroSlider/HeroSlider'
import SectionEvent from '../components/presentationals/organisms/SectionEvent/SectionEvent'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import SectionNewsletter from '../components/presentationals/organisms/SectionNewsletter/SectionNewsletter'
import SectionCoachsContainer from '../containers/SectionCoachsContainer'
import useHome from '../hooks/build/useHome'
import Page from '../layout/Page'

const IndexPage = () => {
  const home = useHome()
  return (
    <Page>
      <HeroSlider
        title={home.sliderTitle}
        subtitle={home.sliderSubtitle}
        images={[home.slider.childImageSharp]}
      />
      <SectionEvent
        id="first-block"
        date={home.event.date}
        title={home.event.title}
        image={home.event.image.childImageSharp}
        text={home.event.text}
        link={home.event.link}
        linkLabel={home.event.linkLabel}
      />
      <Container>
        <SectionImageAndText
          flip={home.block1.imagePosition === 'left'}
          image={home.block1.image.childImageSharp}
          title={home.block1.title}
          text={home.block1.text}
        >
          {home.block1.buttonLabel && home.block1.buttonLink && (
            <Button
              className="secondary"
              label={home.block1.buttonLabel}
              onClick={() => navigateTo(home.block1.buttonLink!)}
            />
          )}
        </SectionImageAndText>
      </Container>
      <SectionNewsletter />
      <Container>
        <SectionImageAndText
          flip={home.block2.imagePosition === 'left'}
          image={home.block2.image.childImageSharp}
          title={home.block2.title}
          text={home.block2.text}
        >
          {home.block2.buttonLabel && home.block2.buttonLink && (
            <Button
              className="secondary"
              label={home.block2.buttonLabel}
              onClick={() => navigateTo(home.block2.buttonLink!)}
            />
          )}
        </SectionImageAndText>
        <SectionCoachsContainer />
        <SectionHashtag />
      </Container>
    </Page>
  )
}

export default IndexPage
