import axios from 'axios'
import { Moment } from 'moment'

import { API_CLASSES_ALL } from '../../constants/api'
import { STRAPI_DATE_FORMAT } from '../../constants/datetime'

const fetchAll = async (date: Moment) => {
  const response = await axios.get(API_CLASSES_ALL, {
    params: {
      date: date.format(STRAPI_DATE_FORMAT)
    }
  })
  return response.data
}

export default fetchAll
