import { graphql, useStaticQuery } from 'gatsby'

const useKeys = (): { stripe_public_key : string} => {
  const data = useStaticQuery(
    graphql`
      query Keys {
        site {
          siteMetadata {
            keys {
              stripe_public_key
            }
          }
        }
      }
    `
  )

  return data.site.siteMetadata.keys
}

export default useKeys
