import React, { FC } from 'react'
import styled from 'styled-components'
import { COLORS, FONTS } from '../../../../assets/styles/variables'
import { media } from '../../../../assets/styles/mixins'

interface IProps {
  className?: string
  values: string[]
}

const Wrapper = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: 60px auto;
  padding: 0 25px;

  ${media.md`
      margin: 100px auto;
  `}

  ul {
    text-align: center;
    list-style: none;
    color: ${COLORS.white};
    font-family: ${FONTS.brand};
    font-weight: 500;
    font-size: 2rem;
  }

  li {
    ${media.md`
    font-size: 2.875rem
  `};

    ${media.sm`
    font-size: 2.375rem;
  `};
  }
`

const SectionValues: FC<IProps> = ({ className, values }) => (
  <Wrapper className={className}>
    <ul>
      {values.map((value, index) => (
        <li key={`values_${index}_${value}`}>{value}</li>
      ))}
    </ul>
  </Wrapper>
)

export default SectionValues
