import { graphql, useStaticQuery } from 'gatsby'

import IExperience from '../../types/Entities/IExperience'

const useExperience = (): IExperience => {
  const data = useStaticQuery(
    graphql`
      query Experience {
        strapiExperience {
          title
          blocks {
            id
            title
            text
            buttonLabel
            buttonLink
            imagePosition
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          buttonLabel
          buttonLink
        }
      }
    `
  )

  return data.strapiExperience
}

export default useExperience
