import { graphql, useStaticQuery } from 'gatsby'

import IHome from '../../types/Entities/IHome'

const useHome = (): IHome => {
  const home = useStaticQuery(
    graphql`
      query Home {
        strapiHome {
          slider {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sliderTitle
          sliderSubtitle
          event {
            date
            title
            text
            link
            linkLabel
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          block1 {
            title
            text
            buttonLabel
            buttonLink
            imagePosition
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          block2 {
            title
            text
            buttonLabel
            buttonLink
            imagePosition
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  const data = home.strapiHome

  // Hack
  // Gatsby do not allow optional attribute in query
  if(data.event.linkLabel === "nothing"){
    delete data.event.link
    delete data.event.linkLabel
  }
  if(data.block1.buttonLabel === "nothing"){
    delete data.block1.buttonLabel.buttonLabel
    delete data.block1.buttonLabel.buttonLabel
  }
  if(data.block2.buttonLabel === "nothing"){
    delete data.block2.buttonLabel.buttonLabel
    delete data.block2.buttonLabel.buttonLabel
  }
  return data
}

export default useHome
