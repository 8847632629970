import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

import Back from '../components/presentationals/atoms/Back/Back'
import Container from '../components/presentationals/atoms/Container/Container'
// import Title from '../components/presentationals/atoms/Title/Title'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import SectionMusic from '../components/presentationals/organisms/SectionMusic/SectionMusic'
// import SectionText from '../components/presentationals/organisms/SectionText/SectionText'
import SectionValues from '../components/presentationals/organisms/SectionValues/SectionValues'
import { ROUTE_COACHS } from '../constants/routes'
import Page from '../layout/Page'

const Grid = styled.section`
  display: flex;
  width: 100%;
  max-width: 1480px;
  margin: auto;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px;
`

const Img = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  width: 33%;
`

const StyledBack = styled(Back)`
  top: 30px;
  left: 16px;
  position: relative;
`

interface IProps {
  data: {
    strapiCoach: {
      name: string
      quote: string
      // history: string
      like: string
      // journey: string
      isWoman: boolean
      playlist: {
        spotifyId: string
      }[]
      values: {
        text: string
      }[]
      avatar: {
        childImageSharp: any
      }
      photos: {
        id: string
        title: string
        image: {
          childImageSharp: any
        }
      }[]
    }
  }
}

export default (props: IProps) => {
  const coach = props.data.strapiCoach
  const playlist = (coach.playlist || []).map(track => track.spotifyId)
  const values = (coach.values || []).map(value => value.text)

  return (
    <Page>
      <Container>
        <StyledBack label="Tous les coachs" to={ROUTE_COACHS} />
        {/* <Title>{coach.name}</Title> */}
      </Container>
      {/* <Grid>
        {coach.photos.map((photo, index) => (
          <Img
            key={`photo_${index}_${photo.id}`}
            alt={photo.title}
            {...photo.image.childImageSharp}
          />
        ))}
      </Grid> */}
      <Container>
        {/* <SectionText title={coach.name} text={coach.history} /> */}
        <SectionImageAndText
          title={coach.name}
          titleTag="h1"
          text={coach.like}
          image={coach.photos[0].image.childImageSharp}
          flip
        />
        <SectionMusic links={playlist} />
        {/* <SectionText
          title={`Une session Journey avec ${coach.isWoman ? 'elle' : 'lui'}`}
          text={coach.journey}
        /> */}
        <SectionValues values={values} />
        <SectionHashtag />
      </Container>
    </Page>
  )
}

export const query = graphql`
  query CoachTemplate($id: String!) {
    strapiCoach(id: { eq: $id }) {
      name
      id
      quote
      history
      like
      journey
      isWoman
      playlist {
        spotifyId
      }
      values {
        text
      }
      avatar {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      photos {
        id
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
    }
  }
`
