import { Redirect, Router } from '@reach/router'
import React from 'react'

import {
  ROUTE_ACCOUNT_PACKS,
  ROUTE_ACCOUNT_PROFILE,
  ROUTE_ACCOUNT_SESSIONS,
  ROUTE_ACCOUNT_SUM_UP
} from '../constants/routes'
import PrivateRoute from '../containers/PrivateRoute'
import Packs from '../pagesDynamics/account/Packs'
import Profile from '../pagesDynamics/account/Profile'
import Sessions from '../pagesDynamics/account/Sessions'
import SumUp from '../pagesDynamics/account/SumUp'

const IndexPage = () => (
  <Router>
    <PrivateRoute path={ROUTE_ACCOUNT_SUM_UP} component={SumUp} />
    <PrivateRoute path={ROUTE_ACCOUNT_SESSIONS} component={Sessions} />
    <PrivateRoute path={ROUTE_ACCOUNT_PROFILE} component={Profile} />
    <PrivateRoute path={ROUTE_ACCOUNT_PACKS} component={Packs} />
    <Redirect from="/" to={ROUTE_ACCOUNT_SUM_UP} noThrow default />
  </Router>
)

export default IndexPage
