import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { COLORS } from '../assets/styles/variables'
import PageLoader from '../components/presentationals/atoms/PageLoader/PageLoader'
import BlockPacks from '../components/presentationals/organisms/BlockPacks/BlockPacks'
import Alert from '../components/presentationals/atoms/Alert/Alert'
import usePacksStore from '../hooks/runtime/usePacksStore'
import { DISABLE_PACKS_BUYING } from '../constants/features'
import { media } from '../assets/styles/mixins'

const Empty = styled.div``

const DisabledPacksBuyingAler = styled(Alert)`
  margin-top: 60px;

  ${media.md`
    margin-top: 100px;  
  `}
`

const Packs = () => {
  const packsStore = usePacksStore()

  // Client-side Runtime Data Fetching
  useEffect(() => {
    packsStore.fetch()
  }, [])

  if (packsStore!.isLoading) {
    return <PageLoader color={COLORS.black} />
  }

  if (!packsStore!.allPacks.length) {
    return <Empty>Aucun pack disponible</Empty>
  }

  return (
    <>
      {DISABLE_PACKS_BUYING && (
        <DisabledPacksBuyingAler
          title="L'achat des packs est temporairement désactivé..."
          subtitle="Il sera de nouveau disponible le 01 Juin 2021. L'équipe de Journey s'excuse pour les délais occasionnés."
        />
      )}

      <BlockPacks
        title="Pour découvrir Journey"
        subtitle="tarifs valables une seule fois"
        packs={packsStore!.discoveryPacks}
        disableButtons={DISABLE_PACKS_BUYING}
      />
      <BlockPacks
        title="Pour aller plus loin"
        packs={packsStore!.nonDiscoveryPacks}
        disableButtons={DISABLE_PACKS_BUYING}
      />
    </>
  )
}

export default observer(Packs)
