import moment from 'moment'

import { MOMENT_DATE_FORMAT, MOMENT_TIME_FORMAT, STRAPI_DATE_FORMAT, STRAPI_TIME_FORMAT } from '../constants/datetime'

export const formatDate = (date: string, format = MOMENT_DATE_FORMAT) => {
  return moment(date, STRAPI_DATE_FORMAT).format(format)
}

export const formatTime = (time: string, format = MOMENT_TIME_FORMAT) => {
  return moment(time, STRAPI_TIME_FORMAT).format(format)
}

export const isBeforeNow = (date: string) => {
  const now = moment()
  return moment(date, STRAPI_DATE_FORMAT).isBefore(now)
}

/**
 * The day when we will allow booking for the first time
 */
export const getDefaultDay = () => moment()
