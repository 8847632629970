import { action, computed, decorate, observable, runInAction } from 'mobx'
import moment, { Moment } from 'moment'

import fetchAll from '../../api/credits/fetchAll'
import ICredits from '../../types/Entities/ICredits'
import { ILoadingState, isLoading } from '../../types/ILoadingState'
import { isBeforeNow } from '../../utils/date'

export class CreditsModel {
  refreshedAt: Moment | null = null
  data: ICredits[] | null = null
  state: ILoadingState = ILoadingState.pending

  /**
   * Setters
   */
  setAll(data: ICredits[]) {
    this.data = data
  }

  add(item: ICredits) {
    const items = this.data || []
    this.data = [...items, item]
  }

  setShouldRefresh() {
    this.refreshedAt = null
  }

  /**
   * Computed values
   */
  get isLoading() {
    return isLoading(this.state)
  }

  get allCredits(): ICredits[] {
    return this.data || []
  }

  get availableCredits(): ICredits[] {
    return this.allCredits.filter(
      credits => !isBeforeNow(credits.expirationDate) && credits.value > 0
    )
  }

  get nonAvailableCredits(): ICredits[] {
    return this.allCredits.filter(credits => isBeforeNow(credits.expirationDate) || !credits.value)
  }

  /**
   * Functions
   */
  needToRefresh() {
    if (!this.refreshedAt) {
      return true
    }

    const reference = this.refreshedAt.clone().add(60, 'minutes')
    return reference.isBefore(moment.now())
  }

  /**
   * Async
   */
  async fetchIfNeeded() {
    return this.needToRefresh() ? this.fetch() : Promise.resolve()
  }

  async fetch() {
    this.state = ILoadingState.loading

    try {
      const data = await fetchAll()
      runInAction(() => {
        this.setAll(data)
        this.refreshedAt = moment()
        this.state = ILoadingState.success
      })
    } catch (error) {
      runInAction(() => {
        this.state = ILoadingState.error
        this.refreshedAt = moment()
      })
    }
  }
}

decorate(CreditsModel, {
  data: observable,
  state: observable,
  // Data
  allCredits: computed,
  availableCredits: computed,
  nonAvailableCredits: computed,
  isLoading: computed,
  // Actions
  setShouldRefresh: action,
  add: action
})
const CreditsStore = new CreditsModel()
export default CreditsStore
