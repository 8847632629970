import moment from 'moment'

import { STRAPI_DATE_FORMAT, STRAPI_TIME_FORMAT } from '../../constants/datetime'
import IClass from '../../types/Entities/IClass'

export default (classToCheck: IClass) => {
  const time = moment(classToCheck.time, STRAPI_TIME_FORMAT)
  const date = moment(classToCheck.date, STRAPI_DATE_FORMAT)

  date.set({
    hour: time.get('hour'),
    minute: time.get('minute')
  })

  const now = moment()

  return date.isBefore(now)
}
