import React from 'react';

function ArrowDown(props) {
  return (
    <svg viewBox="0 0 20 48" {...props}>
      <path fill="none" d="M0 0h20v48H0z" />
      <path fill="#979797" d="M17.3 36.3L11 43.4V.7H9v42.7l-6.3-7.1-1.4 1.4 8.7 9.8 8.7-9.8z" />
    </svg>
  )
}

export default ArrowDown
