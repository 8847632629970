import React from 'react'

import RefreshUserIfNeeded from './RefreshUserIfNeeded'

interface IProps {
  component: React.ReactElement
}

/**
 * PrivateRoute
 *
 * This component is aimed to be used as a @Reach/Router child
 */
export default ({ component: Component, ...rest }: IProps & any) => (
  <RefreshUserIfNeeded>
    <Component {...rest} />
  </RefreshUserIfNeeded>
)
