import React, { useState } from 'react'
import { FormikHelpers } from 'formik'

import PasswordForm from '../components/presentationals/formik/forms/Password/Password'
import FormError from '../components/presentationals/atoms/FormError/FormError'
import FormSuccess from '../components/presentationals/atoms/FormSuccess/FormSuccess'
import changePassword from '../api/user/changePassword'
import storage from '../utils/storage'

export interface IValues {
  oldPassword: string
  password: string
  confirmPassword: string
}

const PasswordFormContainer = () => {
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = (values: IValues, actions: FormikHelpers<IValues>) => {
    changePassword(storage.getJWTToken(), values)
      .then(() => {
        setSuccess('Votre mot de passe a été mis à jour.')
        setError('')
        actions.resetForm()
        actions.validateForm()
      })
      .catch(e => {
        setError(e.response.data.message)
        setSuccess('')
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <div>
      {success && <FormSuccess>{success}</FormSuccess>}
      {error && <FormError>{error}</FormError>}
      <PasswordForm onSubmit={handleSubmit} />
    </div>
  )
}

export default PasswordFormContainer
