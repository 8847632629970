import GatsbyImage from 'gatsby-image'
import GatsbyLink from 'gatsby-link'
import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'
import { ROUTE_COACHS } from '../../../../constants/routes'
import { ButtonStyleSecondary } from '../../tokens/Button/Button'

const Wrapper = styled.section`
  width: 100%;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    margin: 100px auto;
  `}
`

const Grid = styled.ul`
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const GridItem = styled.li`
  margin: 0 5px 10px 5px;
  width: 40%;

  ${media.sm`
    width: 245px;
    display: flex;
    overflow: hidden;
    position: relative;
    margin: 0 17.5px 60px 17.5px;

    :after,
    :before {
      content: '';
      position: absolute;
      height: 27px;
      width: 265px;
      background-color: ${COLORS.black};
      z-index: 10;
      outline: 1px solid transparent;
    }

    :before {
      top: -15px;
      -ms-transform: skewY(-6deg);
      -webkit-transform: skewY(-6deg);
      transform: skewY(-6deg);
    }

    :after {
      bottom: -10px;
      -ms-transform: skewY(-6deg);
      -webkit-transform: skewY(-6deg);
      transform: skewY(-6deg);
    }
  `}
`
const Img = styled(GatsbyImage)`
  width: 100%;
  ${media.sm`
    width: 245px;
  `}
`

const Link = styled(GatsbyLink)`
  color: ${COLORS.white};
  font-family: ${FONTS.sansSerif};
  font-weight: 500;
  text-decoration: none;

  :hover {
    text-decoration: none;
  }

  ${media.sm`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  `}

  span {
    padding: 10px 0 30px 0;
    margin: auto;
    display: block;
    font-size: 1.125rem;
    text-align: center;

    ${media.sm`
      font-size: 1.5rem;
      z-index: 2;
      position: absolute;
    `}
  }

  ${media.sm`
    :before {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(9, 151, 136, 0.6);
    }

    span,
    :before {
      opacity: 0;
      transition: ${TRANSITIONS.base};
    }

    :hover span,
    :hover:before {
      opacity: 1;
    }
  `}
`

const Title = styled.h2`
  font-size: 1.875rem;
  font-family: ${FONTS.brand};
  font-weight: 500;
  color: ${COLORS.white};
  margin: 0;
  padding: 0;
  text-align: center;

  ${media.sm`
    font-size: 2.235rem;
  `}
`

const CoachsLink = styled(GatsbyLink)`
  ${ButtonStyleSecondary}
`

interface IProps {
  className?: string
  coachs: {
    name: string
    avatar: {
      childImageSharp: any
    }
  }[]
}

const SectionCoachs: FC<IProps> = ({ className, coachs }) => (
  <Wrapper className={className}>
    <Title>Des Coachs qui vous inspirent</Title>
    <Grid>
      {coachs.map(coach => (
        <GridItem key={`coach_${coach.name}`}>
          <Link to={`/coachs/${coach.name.toLowerCase()}`}>
            <Img {...coach.avatar.childImageSharp} alt={`Photo de ${coach.name}`} />
            <span>{coach.name}</span>
          </Link>
        </GridItem>
      ))}
    </Grid>
    <CoachsLink to={ROUTE_COACHS}>
      <span>Découvrir tous les Coachs</span>
    </CoachsLink>
  </Wrapper>
)

export default SectionCoachs
