import { css } from 'styled-components';

import { FONTS } from '../../../../assets/styles/variables';

const base = css`
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
  margin: 0;
  padding: 0;
`

export const Heading1 = css`
  ${base};
  font-family: ${FONTS.brand};
  font-size: 5rem;
  font-weight: 500;
`
export const Heading2 = css`
  ${base};
  font-family: ${FONTS.brand};
  font-size: 2.375rem;
  font-weight: 500;
  line-height: 1.33;
`
export const Heading3 = css`
  ${base};
  font-family: ${FONTS.brand};
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 1.33;
`
export const Heading4 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 1.5rem;
  line-height: 1.33;
`
export const Heading5 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.31;
  color: var(--black);
`
export const Phrase1 = css`
  ${base};
  font-family: ${FONTS.brand};
  font-size: 2.875rem;
  font-weight: 500;
  line-height: 1.3;
`
export const Body1 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 1.125rem;
  line-height: 1.78;
`
export const Body2 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 0.875rem;
  line-height: 1.57;
`

export const Text1 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 1rem;
  text-align: center;
`

export const Caption1 = css`
  ${base};
  font-family: ${FONTS.sansSerif};
  font-size: 0.875rem;
  line-height: 1.29;
`
