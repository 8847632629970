import { MenuItem } from '@material-ui/core'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React from 'react'

import { SHOES_SIZES_OPTIONS } from '../../../../../constants/user'
import DatePickerField from '../../fields/DatePickerField/DatePickerField'
import SelectField from '../../fields/SelectField/SelectField'
import TextField from '../../fields/TextField/TextField'
import validationSchema from './Info.validationSchema'

export interface IValues {
  firstname: string
  lastname: string
  phone: string
  email: string
  birthdate: string
  shoes: string
}

interface IProps {
  className?: string
  onSubmit: (values: IValues, action: FormikHelpers<IValues>) => void
  initialValues: IValues
}

export default function InfoForm({ onSubmit, className, initialValues }: IProps) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema()} onSubmit={onSubmit}>
      <Form className={className}>
        <Field
          fullWidth
          required
          name="firstname"
          label="Prénom"
          placeholder="Votre prénom"
          type="text"
          component={TextField}
          disabled
        />
        <Field
          fullWidth
          required
          name="lastname"
          label="Nom"
          placeholder="Votre nom"
          type="text"
          component={TextField}
          disabled
        />
        <Field
          fullWidth
          required
          name="phone"
          label="Numéro de téléphone"
          placeholder="Votre numéro de téléphone"
          type="phone"
          component={TextField}
          disabled
        />
        <Field
          fullWidth
          required
          name="email"
          label="Adresse mail"
          placeholder="Votre adresse email"
          type="email"
          component={TextField}
          disabled
        />
        <Field
          fullWidth
          name="birthdate"
          label="Votre date de naissance"
          component={DatePickerField}
          disabled
        />
        <Field
          fullWidth
          required
          name="shoes"
          select
          options={SHOES_SIZES_OPTIONS}
          label="Pointure de chaussures"
          placeholder="Pointure de chaussures"
          component={SelectField}
          disabled
        >
          {SHOES_SIZES_OPTIONS.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
        {/* <Button type="submit" label="Mettre à jour" /> */}
      </Form>
    </Formik>
  )
}
