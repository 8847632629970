import { Moment } from 'moment'
import React from 'react'
import SlickSlider from 'react-slick'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'
import SLIDER_SETTINGS from './ClassesDateSlider.settings'
import { ClassesDateSliderArrowNext, ClassesDateSliderArrowPrev } from './ClassesDateSliderArrows'

const Slide = styled.div<{ selected: boolean }>`
  color: white;
  border: 1px solid white;
  box-sizing: border-box;
  text-align: center;
  padding: 22px 12px;
  cursor: pointer;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? COLORS.brand : 'transparent'};

  span {
    text-transform: capitalize;
  }

  ${media.sm`
    padding: 26px;
  `}
`

const Slider = styled(SlickSlider)`
  width: calc(100% + 25px);
  padding-top: 50px;
  position: relative;

  ${media.md`
    width: 100%
  `}
`

interface IProps {
  className?: string
  currentDay: Moment
  days: Moment[]
  onDayClick: (day: Moment) => void
}

function ClassesDateSlider({ className, currentDay, days, onDayClick }: IProps) {
  return (
    <Slider
      className={className}
      {...SLIDER_SETTINGS}
      nextArrow={<ClassesDateSliderArrowNext />}
      prevArrow={<ClassesDateSliderArrowPrev />}
    >
      {days.map((day, index) => (
        <Slide
          key={`Date_${index}`}
          onClick={() => onDayClick(day)}
          selected={day.isSame(currentDay, 'day')}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: day
                .locale('fr')
                .format('dddd <br/> DD MMM')
                .toString()
            }}
          />
        </Slide>
      ))}
    </Slider>
  )
}

export default ClassesDateSlider
