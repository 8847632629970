import React from 'react';

function BurgerOpen(props) {
  return (
    <svg width={25} height={23} {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h25v23H0z" />
        <path fill="#000" d="M0 4h25v3H0zM0 10h20v3H0zM0 16h16v3H0z" />
      </g>
    </svg>
  )
}

export default BurgerOpen
