import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../../assets/styles/variables'

interface IProps {
  color?: string
}

const Wrapper = styled.div<IProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(100vh / 4);
  margin-bottom: calc(100vh / 4);

  color: ${(props: IProps) => props.color || COLORS.brand};
`

export default function PageLoader(props: IProps) {
  return (
    <Wrapper {...props}>
      <CircularProgress color="inherit" />
    </Wrapper>
  )
}
