import React, { useLayoutEffect, ReactNode } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'

interface IProps {
  children: ReactNode
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;

  ${media.md`
    z-index: 200;
  `}
`

const ModalCloak = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100vh;
  width: 100%;
  background-color: #1a202c;
  opacity: 0.25;
`

const ModalBody = styled.div`
  position: absolute;
  left: 5%;
  top: calc(70px + 5vw);
  width: 90%;
  max-width: 800px;
  z-index: 20;
  margin: 0 auto;
  background-color: #fff;
  overflow-y: scroll;
  padding: 2rem 1.5rem;

  ${media.md`
    left: 50%;
    top: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 40px);
    padding: 2rem 2.5rem;
  `}
`

export default function Modal({ children }: IProps) {
  return (
    <ModalWrapper>
      <ModalCloak />

      <ModalBody>{children}</ModalBody>
    </ModalWrapper>
  )
}
