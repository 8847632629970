import React, { FC } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  title?: string | React.ReactNode
  text?: string | React.ReactNode
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 60px;
`

const SectionCGV: FC<IProps> = ({ className }) => (
  <Wrapper className={className}>
    <p>* Commande avec obligation de paiement.</p>
  </Wrapper>
)

export default SectionCGV
