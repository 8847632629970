import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { COLORS } from '../assets/styles/variables'
import BlockWithTitle from '../components/presentationals/atoms/BlockWithTitle/BlockWithTitle'
import PageLoader from '../components/presentationals/atoms/PageLoader/PageLoader'
import useCreditsStore from '../hooks/runtime/useCreditsStore'
import ICredits from '../types/Entities/ICredits'
import { formatDate } from '../utils/date'

const Empty = styled.div``

const ExpirationDate = styled.span`
  color: ${COLORS.brownGrey};
  font-size: 0.9rem;
`

function CreditsNonAvailable() {
  const creditsStore = useCreditsStore()

  // Client-side Runtime Data Fetching
  useEffect(() => {
    creditsStore.fetchIfNeeded()
  }, [])

  if (creditsStore!.isLoading) {
    return <PageLoader color={COLORS.black} />
  }

  if (!creditsStore.nonAvailableCredits.length) {
    return <></>
  }

  return (
    <BlockWithTitle title="Historique des achats">
      <div>
        {creditsStore.nonAvailableCredits.map((credits: ICredits) => (
          <p key={`credits_${credits.id}`}>
            {credits.pack.name}
            <br />
            <ExpirationDate>Expire le : {formatDate(credits.expirationDate)}</ExpirationDate>
          </p>
        ))}
      </div>
    </BlockWithTitle>
  )
}

export default observer(CreditsNonAvailable)
