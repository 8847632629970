import React, { FC } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../../assets/styles/variables'
import { Body1 } from '../../tokens/Font/Font'

const Wrapper = styled.p`
  ${Body1};
  color: ${COLORS.brand};
  margin-bottom: 20px;
`

interface ContainerProps {
  className?: string
  children: React.ReactNode
}

const FormSuccess: FC<ContainerProps> = ({ children, className }) => {
  return <Wrapper>{children}</Wrapper>
}

export default FormSuccess
