import GatsbyLink, { navigateTo } from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import Button from '../components/presentationals/atoms/Button/Button'
import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionText from '../components/presentationals/organisms/SectionText/SectionText'
import { ButtonStyleSecondary } from '../components/presentationals/tokens/Button/Button'
import { ROUTE_DISCOVER_FIRST_SESSION, ROUTE_LOGIN } from '../constants/routes'
import useSiteMetadata from '../hooks/build/useSiteMetadata'
import Page from '../layout/Page'

const FirstSessionLink = styled(GatsbyLink)`
  ${ButtonStyleSecondary};
  margin: 60px auto;
`
export default () => {
  const { contact } = useSiteMetadata()

  return (
    <Page>
      <Container>
        <Title>Comment ça fonctionne ?</Title>
        <SectionText
          title="Crédits"
          text={
            <>
              Vous êtes prêt à voyager avec Journey ?<br />
              Pour cela, toutes les réservations se font exclusivement en ligne. Il suffit de créer
              un compte et de choisir le créneau qui vous convient, pour votre première session,
              nous vous proposons un tarif spécial à 12 euros pour que vous puissiez découvrir
              l’expérience Journey.
              <br />
              Vous pourrez ensuite acheter soit des sessions à l’unité soit des packs de sessions.
            </>
          }
        >
          <Button
            className="secondary"
            label="Créer mon compte"
            onClick={() => navigateTo(ROUTE_LOGIN)}
          />
        </SectionText>
        <SectionText
          title="Réservation"
          text={
            <>
              Les réservations sont hebdomadaires. Nous ouvrons notre planning des réservations pour
              la semaine chaque lundi à midi. Vous pourrez ainsi réserver vos sessions du mardi au
              lundi suivant. Sur chaque session vous choisissez votre vélo sur un plan du studio.
            </>
          }
        />
        <SectionText
          title="Premier rang"
          text={
            <>
              Le premier rang est fortement conseillé aux initiés.
              <br />
              C’est eux qui montrent l’exemple pour tout le groupe !
            </>
          }
        />
        <SectionText
          title="Annulation"
          text={
            <>
              Vous pouvez annuler un cours jusqu’à 24h avant le début de la session.
              <br />
              Pour cela, il suffit de nous envoyer un mail à{' '}
              <a href={`mailto:${contact.email}r`}>{contact.email}</a> avec vos informations pour
              que nous vous annulions le cours et vous recréditions un crédit.
              <br />
              Les sessions annulées dans les 24h précédant la session ne sont pas recréditées.
            </>
          }
        />
        <FirstSessionLink to={ROUTE_DISCOVER_FIRST_SESSION}>
          <span>En savoir plus sur ma première session</span>
        </FirstSessionLink>
      </Container>
    </Page>
  )
}
