import React from 'react';
import styled from 'styled-components';

import { media } from '../assets/styles/mixins';
import { COLORS } from '../assets/styles/variables';
import Back from '../components/presentationals/atoms/Back/Back';
import BlockWithTitle from '../components/presentationals/atoms/BlockWithTitle/BlockWithTitle';
import Container from '../components/presentationals/atoms/Container/Container';
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag';
import { Heading1, Heading2 } from '../components/presentationals/tokens/Font/Font';
import { ROUTE_LOGIN } from '../constants/routes';
import RefreshUserIfNeeded from '../containers/RefreshUserIfNeeded';
import SignupFormContainer from '../containers/SignupForm';
import Page from '../layout/Page';

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
`

const StyledSignupFormContainer = styled(SignupFormContainer)`
  width: 100%;
  max-width: 500px;
`

const StyledBack = styled(Back)`
  top: 30px;
  left: 16px;
  position: relative;
`

const Title = styled.h1`
  ${Heading2}
  margin: 60px 0 20px;

  ${media.md`
    ${Heading1}
    margin: 80px 0 40px;
  `}
`

export default function Login() {
  return (
    <RefreshUserIfNeeded>
      <Page>
        <ContainerWhiteBackground fluid>
          <StyledBack label="J'ai déjà un compte" to={ROUTE_LOGIN} />
          <Title>Créez votre compte Journey</Title>
          <BlockWithTitle title="Informations">
            <StyledSignupFormContainer />
          </BlockWithTitle>
        </ContainerWhiteBackground>
        <Container>
          <SectionHashtag />
        </Container>
      </Page>
    </RefreshUserIfNeeded>
  )
}
