import React from 'react'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import validationSchema from './Bikes.validationSchema'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Button from '../../../atoms/Button/Button'

const WrapperForm = styled(Form)`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
`

const BikeNumber = styled.div`
  position: relative;
  height: 83px;
  width: 55px;
  border: 1px solid black;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
`
const LeftSideBikeNumber = styled.div`
  position: relative;
  height: 55px;
  width: 83px;
  border: 1px solid black;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
const RightSideBikeNumber = styled.div`
  position: relative;
  height: 55px;
  width: 83px;
  border: 1px solid black;
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
`

const LeftBikesWrapper = styled.div`
  margin-left: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const RightBikesWrapper = styled.div`
  margin-right: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const BikesCoachs = styled.div`
  height: 120px;
  width: 60px;
  border: 1px solid black;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: not-allowed;
  position: relative;
  background-color: black;
  margin-top: 20px;
  color: white;
`
const Input = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  margin: 30px 0 80px 0;
`

const Bike = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;

  &:hover {
    background-color: rgb(9, 151, 136);
    color: white;
  }

  &
    input:checked
    ~ ${BikeNumber},
    &
    input:checked
    ~ ${RightSideBikeNumber},
    &
    input:checked
    ~ ${LeftSideBikeNumber} {
    background-color: rgb(9, 151, 136);
    color: white;
  }

  &
    input:disabled
    ~ ${BikeNumber},
    &
    input:disabled
    ~ ${RightSideBikeNumber},
    &
    input:disabled
    ~ ${LeftSideBikeNumber} {
    background-color: #696969;
    color: #d3d3d3;
    pointer-events: none;
  }
`

export interface IValues {
  bike?: string
}

interface IProps {
  className?: string
  onSubmit: (values: IValues, action: FormikHelpers<IValues>) => void
  initialValues?: IValues
  bikes: Record<string, boolean>
}

export default function BikesForm({ onSubmit, className, initialValues = {}, bikes = {} }: IProps) {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema()} onSubmit={onSubmit}>
      {({ isValid, isSubmitting, handleSubmit, dirty }: FormikProps<IValues>) => (
        <WrapperForm className={className} onSubmit={handleSubmit}>
          <Row>
            <label>
              <Bike>
                <Input type="radio" value="24" name="bike" disabled={bikes[24] ? 'disabled' : ''} />
                <BikeNumber>
                  24
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="23" name="bike" disabled={bikes[23] ? 'disabled' : ''} />
                <BikeNumber>
                  23
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="22" name="bike" disabled={bikes[22] ? 'disabled' : ''} />
                <BikeNumber>
                  22
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="21" name="bike" disabled={bikes[21] ? 'disabled' : ''} />
                <BikeNumber>
                  21
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="20" name="bike" disabled={bikes[20] ? 'disabled' : ''} />
                <BikeNumber>
                  20
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
          </Row>
          <Row>
            <label>
              <Bike>
                <Input type="radio" value="19" name="bike" disabled={bikes[19] ? 'disabled' : ''} />
                <BikeNumber>
                  19
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="18" name="bike" disabled={bikes[18] ? 'disabled' : ''} />
                <BikeNumber>
                  18
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="17" name="bike" disabled={bikes[17] ? 'disabled' : ''} />
                <BikeNumber>
                  17
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="16" name="bike" disabled={bikes[16] ? 'disabled' : ''} />
                <BikeNumber>
                  16
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="15" name="bike" disabled={bikes[15] ? 'disabled' : ''} />
                <BikeNumber>
                  15
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
          </Row>
          <Row>
            <label>
              <Bike>
                <Input type="radio" value="14" name="bike" disabled={bikes[14] ? 'disabled' : ''} />
                <BikeNumber>
                  14
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="13" name="bike" disabled={bikes[13] ? 'disabled' : ''} />
                <BikeNumber>
                  13
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="12" name="bike" disabled={bikes[12] ? 'disabled' : ''} />
                <BikeNumber>
                  12
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="11" name="bike" disabled={bikes[11] ? 'disabled' : ''} />
                <BikeNumber>
                  11
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="10" name="bike" disabled={bikes[10] ? 'disabled' : ''} />
                <BikeNumber>
                  10
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
          </Row>
          <Row>
            <label>
              <Bike>
                <Input type="radio" value="9" name="bike" disabled={bikes[9] ? 'disabled' : ''} />
                <BikeNumber>
                  9
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="8" name="bike" disabled={bikes[8] ? 'disabled' : ''} />
                <BikeNumber>
                  8
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="7" name="bike" disabled={bikes[7] ? 'disabled' : ''} />
                <BikeNumber>
                  7
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="6" name="bike" disabled={bikes[6] ? 'disabled' : ''} />
                <BikeNumber>
                  6
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
            <label>
              <Bike>
                <Input type="radio" value="5" name="bike" disabled={bikes[5] ? 'disabled' : ''} />
                <BikeNumber>
                  5
                  <FontAwesomeIcon icon={faChevronDown} />
                </BikeNumber>
              </Bike>
            </label>
          </Row>
          <Row>
            <LeftBikesWrapper>
              <label>
                <Bike>
                  <Input type="radio" value="4" disabled={bikes[4] ? 'disabled' : ''} name="bike" />
                  <LeftSideBikeNumber>
                    4
                    <FontAwesomeIcon icon={faChevronRight} />
                  </LeftSideBikeNumber>
                </Bike>
              </label>
              <label>
                <Bike>
                  <Input type="radio" value="3" disabled={bikes[3] ? 'disabled' : ''} name="bike" />
                  <LeftSideBikeNumber>
                    3
                    <FontAwesomeIcon icon={faChevronRight} />
                  </LeftSideBikeNumber>
                </Bike>
              </label>
            </LeftBikesWrapper>
            <BikesCoachs>
              <FontAwesomeIcon icon={faChevronUp} />
              coach
            </BikesCoachs>
            <RightBikesWrapper>
              <label>
                <Bike>
                  <Input type="radio" value="2" disabled={bikes[2] ? 'disabled' : ''} name="bike" />
                  <RightSideBikeNumber>
                    2
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </RightSideBikeNumber>
                </Bike>
              </label>
              <label>
                <Bike>
                  <Input type="radio" value="1" disabled={bikes[1] ? 'disabled' : ''} name="bike" />
                  <RightSideBikeNumber>
                    1
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </RightSideBikeNumber>
                </Bike>
              </label>
            </RightBikesWrapper>
          </Row>
          <ButtonContainer>
            <Button
              fluid
              type="submit"
              label="Valider la réservation"
              disabled={!dirty || !isValid || isSubmitting}
            />
          </ButtonContainer>
        </WrapperForm>
      )}
    </Formik>
  )
}
