import Link, { navigate } from 'gatsby-link'
import React from 'react'
import styled, { css } from 'styled-components'

import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'
import logo from '../../../../assets/svg/logo_journey_green.svg'
import { ROUTE_ACCOUNT, ROUTE_SESSION_SELECT } from '../../../../constants/routes'
import Button from '../../atoms/Button/Button'
import BurgerClose from '../../atoms/Icon/BurgerClose'
import BurgerOpen from '../../atoms/Icon/BurgerOpen'

const Wrapper = styled.div`
  width: 100%;
  z-index: 100;
`

const Banner = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 10px;
  z-index: 110;
  position: fixed;
  right: 0;
  left: 0;
`

const Drawer = styled.div<{ isOpen: boolean }>`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
  background-color: ${COLORS.white};
  width: 100%;
  min-height: 100%;
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
`

const Toggle = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  display: block;
  background: transparent;
  width: 35px;
`

const Logo = styled.img`
  height: 30px;
  margin-left: 20px;
  margin-top: 10px;
`

const List = styled.ul`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 80px 20px 30px 20px;
`

const linkStyle = css`
  font-family: ${FONTS.sansSerif};
  font-weight: 500;
  color: ${COLORS.black};
  text-decoration: none;
  transition: ${TRANSITIONS.base}

  :hover {
    color: ${COLORS.brand};
    text-decoration: none;
  }

`

const NavigationItem = styled.li`
  margin-bottom: 25px;
`

const NavigationLink = styled(Link)`
  ${linkStyle};
  font-size: 1.75rem;
`

const SocialItem = styled.li`
  margin-bottom: 20px;
`

const SocialLink = styled.a`
  ${linkStyle};
  font-size: 1.375rem;
`

const ExtaItem = styled.li`
  margin-bottom: 20px;
`

const ExtraLink = styled(Link)`
  ${linkStyle};
  font-size: 1rem;
`

interface IProps {
  menuItems: { label: string; to: string }[]
  className?: string
}

interface IState {
  isOpen: boolean
}

export default class HeaderMobile extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  public handleClick = () => {
    this.setState((state: IState) => ({ isOpen: !state.isOpen }))
  }

  public render() {
    const { menuItems, className } = this.props

    const toggleClass = this.state.isOpen ? 'is-active' : ''

    return (
      <Wrapper className={className}>
        <Banner>
          <Toggle className={toggleClass} onClick={this.handleClick}>
            {this.state.isOpen ? <BurgerClose /> : <BurgerOpen />}
          </Toggle>
          <Link to="/">
            <Logo src={logo} alt="Journey Studio Cycle" />
          </Link>
        </Banner>
        <Drawer isOpen={this.state.isOpen}>
          <List>
            {menuItems.map((navItem, index) => (
              <NavigationItem key={`Header_NavivationItem_${index}`}>
                <NavigationLink to={navItem.to}>{navItem.label}</NavigationLink>
              </NavigationItem>
            ))}
            <NavigationItem>
              <NavigationLink to={ROUTE_ACCOUNT}>Mon espace</NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <Button label="Booker une session" onClick={() => navigate(ROUTE_SESSION_SELECT)} />
            </NavigationItem>
          </List>
          <List>
            <SocialItem>
              <SocialLink
                href="https://www.facebook.com/journeystudiocycle"
                target="_blank"
                title="facebook"
              >
                Facebook
              </SocialLink>
            </SocialItem>
            <SocialItem>
              <SocialLink
                href="https://www.instagram.com/journeystudiocycle"
                target="_blank"
                title="instagram"
              >
                Instagram
              </SocialLink>
            </SocialItem>
          </List>
          <List>
            <ExtaItem>
              <ExtraLink to="/recrutement">Recrutement</ExtraLink>
            </ExtaItem>
            <ExtaItem>
              <ExtraLink to="/contact">Contact</ExtraLink>
            </ExtaItem>
          </List>
        </Drawer>
      </Wrapper>
    )
  }
}
