import { graphql, useStaticQuery } from 'gatsby'

import ILegale from '../../types/Entities/ILegale'

const useLegale = (): ILegale => {
  const data = useStaticQuery(
    graphql`
      query Legale {
        strapiLegale {
          title
          text
        }
      }
    `
  )

  return data.strapiLegale
}

export default useLegale
