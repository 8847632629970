import { action, computed, decorate, observable, runInAction } from 'mobx'
import moment, { Moment } from 'moment'

import fetchAll from '../../api/sessions/fetchAll'
import ISession from '../../types/Entities/ISession'
import { ILoadingState, isLoading } from '../../types/ILoadingState'
import isClassFinished from '../../utils/models/isClassFinished'

export class SessionsModel {
  refreshedAt: Moment | null = null
  data: ISession[] | null = null
  state: ILoadingState = ILoadingState.pending

  /**
   * Setters
   */
  setAll(data: ISession[]) {
    this.data = data
  }

  add(item: ISession) {
    const items = this.data || []
    this.data = [...items, item]
  }

  /**
   * Computed values
   */
  get isLoading() {
    return isLoading(this.state)
  }

  get allSessions(): ISession[] {
    return this.data || []
  }

  get nextSessions(): ISession[] {
    return this.allSessions.filter(session => !isClassFinished(session.class))
  }

  get passedSessions(): ISession[] {
    return this.allSessions.filter(session => isClassFinished(session.class))
  }

  /**
   * Functions
   */
  needToRefresh() {
    if (!this.refreshedAt) {
      return true
    }

    const reference = this.refreshedAt.clone().add(60, 'minutes')
    return reference.isBefore(moment.now())
  }

  /**
   * Async
   */
  async fetchIfNeeded() {
    return this.needToRefresh() ? this.fetch() : Promise.resolve()
  }

  async fetch() {
    this.state = ILoadingState.loading

    try {
      const data = await fetchAll()
      runInAction(() => {
        this.setAll(data)
        this.refreshedAt = moment()
        this.state = ILoadingState.success
      })
    } catch (error) {
      runInAction(() => {
        this.state = ILoadingState.error
        this.refreshedAt = moment()
      })
    }
  }
}

decorate(SessionsModel, {
  data: observable,
  state: observable,
  // Data
  allSessions: computed,
  nextSessions: computed,
  passedSessions: computed,
  isLoading: computed,
  // Actions
  needToRefresh: action,
  add: action
})
const SessionsStore = new SessionsModel()
export default SessionsStore
