import React from 'react'

import Container from '../../components/presentationals/atoms/Container/Container'
import Title from '../../components/presentationals/atoms/Title/Title'
import SelectClassContainer from '../../containers/SelectClass'
import Page from '../../layout/Page'

export default () => (
  <Page>
    <Container>
      <Title>Choisissez votre session</Title>
      <SelectClassContainer />
    </Container>
  </Page>
)
