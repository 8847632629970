import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    oldPassword: (yup.string() as any).password().required(),
    password: (yup.string() as any).password().required(),
    confirmPassword: (yup.string() as any).password().required()
  })

export default validationSchema
