import React from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'
import { Heading2, Heading4, Heading5 } from '../../tokens/Font/Font'

interface IProps {
  title: string
  subtitle?: string
  className?: string
}

const Wrapper = styled.div`
  border-left: 5px solid ${COLORS.brand};
  padding: 10px 0 10px 30px;

  ${media.md`
    transform: skewX(-15deg);

    & > * {
      transform: skewX(15deg);
    }
  `}
`

const Title = styled.p`
  ${Heading4}
  color: ${COLORS.brand} !important;

  ${media.md`
    ${Heading2}
  `}
`

const Subtitle = styled.p`
  ${Heading5};
  padding-top: 10px;

  ${media.md`
    padding-left: 13px;
  `}
`

export default function Alert({ className, title, subtitle }: IProps) {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}
