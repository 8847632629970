import React, { useEffect } from 'react'
import styled from 'styled-components'

import Container from '../components/presentationals/atoms/Container/Container'
import Question from '../components/presentationals/atoms/Question/Question'
import Title from '../components/presentationals/atoms/Title/Title'
import Page from '../layout/Page'

const Questions = styled.ul`
  & > * {
    margin-bottom: 60px;
  }
`

export default function FAQ() {
  // Similar to componentDidMount / componentDidUpdate
  useEffect(() => {
    // Hack to be able to scroll to hash
    // because of header height
    const { hash } = window.location
    if (hash) {
      const el = document.querySelector(hash)

      if (el) {
        var top = (el as HTMLElement).offsetTop - 80
        window.scroll({ top, behavior: 'smooth' })
      }
    }
  })

  return (
    <Page>
      <Container>
        <Title>Questions fréquentes</Title>
        <Questions>
          <Question id="comment-reserver" title="Comment réserver ?">
            Toutes les réservations se font exclusivement en ligne.
            <br />
            Il suffit de créer un compte, d’acheter un crédit et de choisir le créneau qui vous
            convient.
          </Question>
          <Question id="quand-reserver" title="Quand réserver ?">
            Vous pouvez réserver quand vous voulez pour la semaine en cours. Nous ouvrons notre
            planning des réservations pour la semaine chaque lundi à midi.
            <br />
            Vous pourrez ainsi réserver vos sessions du mardi au lundi suivant.
          </Question>
          <Question id="annulation" title="Puis-je annuler ?">
            Vous pouvez annuler un cours jusqu’à 24h avant le début de la session.
            <br />
            Pour cela, il suffit de nous envoyer un mail à hello@journeystudio.fr avec vos
            informations pour que nous vous annulions le cours et vous recréditions un crédit.
            <br />
            Les sessions annulées dans les 24h précédant la session ne sont pas recréditées.
          </Question>
          <Question id="niveaux" title="Y a-t-il des groupes de niveaux ?">
            Les sessions Journey sont faites pour que tout le monde puisse participer, que vous
            soyez un habitué ou un débutant, sportif ou non. <br />
            Nos coachs seront là pour que votre voyage se passe de la meilleure des manières.
          </Question>
          <Question id="mot-de-passe-oublie" title="J'ai perdu mon mot de passe, que faire ?">
            Si jamais vous perdez votre mot de passe, contactez-nous par mail avec l'adresse de
            votre compte à hello@journeystudio.fr, nous vous enverrons un nouveau mot de passe dans
            les plus brefs délais.
            <br />
            Vous pourrez ensuite le modifier dans votre espace connecté.
          </Question>
          <Question id="supprimer-mon-compte" title="Comment faire pour supprimer mon compte ?">
            Nous supprimerons votre compte sur simple demande faite par mail avec l’adresse de votre
            compte à hello@journeystudio.fr. Attention, il ne sert à rien d'envisager supprimer
            votre compte pour bénéficier à nouveau des offres de découverte. Cela ne fonctionnera
            pas ;-)
          </Question>
        </Questions>
      </Container>
    </Page>
  )
}
