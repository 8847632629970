import React from 'react'
import styled from 'styled-components'

import { media } from '../../assets/styles/mixins'
import { COLORS } from '../../assets/styles/variables'
import Container from '../../components/presentationals/atoms/Container/Container'
import Title from '../../components/presentationals/atoms/Title/Title'
import SectionCGV from '../../components/presentationals/organisms/SectionCGV/SectionCGV'
import SectionHashtag from '../../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SelectPackContainer from '../../containers/SelectPack'
import Page from '../../layout/Page'

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
  padding-bottom: 60px;
  ${media.md`
    padding-bottom: 100px;
  `}
`

export default function() {
  return (
    <Page>
      <Container>
        <Title>
          Le Pack de crédits
          <br />
          qui vous correspond.
        </Title>
      </Container>
      <ContainerWhiteBackground fluid>
        <SelectPackContainer />
      </ContainerWhiteBackground>
      <Container>
        <SectionCGV />
        <SectionHashtag />
      </Container>
    </Page>
  )
}
