import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { media } from '../assets/styles/mixins'
import { COLORS } from '../assets/styles/variables'
import Back from '../components/presentationals/atoms/Back/Back'
import Container from '../components/presentationals/atoms/Container/Container'
import PageLoader from '../components/presentationals/atoms/PageLoader/PageLoader'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import CardForm from '../components/presentationals/stripe/forms/CardForm'
import { Heading1, Heading2, Heading3, Heading4 } from '../components/presentationals/tokens/Font/Font'
import { ROUTE_PACK_SELECT } from '../constants/routes'
import useKeys from '../hooks/build/useKeys'
import usePacksStore from '../hooks/runtime/usePacksStore'
import IPack from '../types/Entities/IPack'

interface IProps {
  identifier: string
}

const Title = styled.h1`
  ${Heading2}
  margin: 60px 0 10px;

  ${media.md`
    ${Heading1};
    margin: 80px 0 10px;
  `}
`

const SubTitle = styled.h2`
  ${Heading4};
  max-width: 870px;
  margin-bottom: 60px;

  ${media.md`
    ${Heading3};
    margin-bottom: 80px
  `};
`

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
  padding: 60px 0;
  ${media.md`
    padding: 80px 0;
  `}
`

const StyledBack = styled(Back)`
  top: 30px;
  left: 16px;
  position: relative;
`

const Sorry = styled.div`
  ${Heading4}
  margin-bottom: 30px;
`

const Content = styled.div`
  width: 100%;

  ${media.md`
    max-width: 600px;
  `}
`

function BuyPack({ identifier }: IProps) {
  const packsStore = usePacksStore()
  const { stripe_public_key: stripeKey } = useKeys()

  // Fech pack with given identifier
  useEffect(() => {
    packsStore.fetchIfNeeded()
  }, [identifier])

  // Loading state
  if (packsStore.isLoading) {
    return (
      <Container>
        <Title>Merci de patienter...</Title>
        <PageLoader />
      </Container>
    )
  }

  const pack = packsStore.allPacks.find((pack: IPack) => pack.id.toString() === identifier)

  // No result
  if (!pack) {
    return (
      <Container>
        <Title>Oups !</Title>
        <Sorry>Désolé, nous ne trouvons pas le pack que vous cherchez !</Sorry>
        <Back label="Packs disponibles" to={ROUTE_PACK_SELECT} />
      </Container>
    )
  }

  return (
    <>
      <Container>
        <StyledBack label="Annuler" to={ROUTE_PACK_SELECT} />
        <Title>Paiement</Title>
        <SubTitle>
          {pack.name} - {pack.price}
        </SubTitle>
      </Container>
      <ContainerWhiteBackground fluid>
        <Content>
          <CardForm pack={pack} stripeKey={stripeKey} />
        </Content>
      </ContainerWhiteBackground>
      <Container>
        <SectionHashtag />
      </Container>
    </>
  )
}

export default observer(BuyPack)
