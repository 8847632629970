import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    // birthdate: yup.date().required(),
    shoes: yup.number().required(),
    phone: (yup.string() as any).phone().required(),
    email: yup
      .string()
      .email()
      .required()
  })

export default validationSchema
