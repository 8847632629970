import React from 'react'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionEvent from '../components/presentationals/organisms/SectionEvent/SectionEvent'
import useEvents from '../hooks/build/useEvents'
import Page from '../layout/Page'

export default () => {
  const events = useEvents()

  return (
    <Page>
      <Container>
        <Title>Événements</Title>
      </Container>
      {events.reverse().map(event => (
        <SectionEvent
          key={`Event_${event.id}`}
          date={event.date}
          title={event.title}
          text={event.text}
          image={event.image.childImageSharp}
          link={event.link}
          linkLabel={event.linkLabel}
        />
      ))}
    </Page>
  )
}
