import React, { useMemo } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import Modal from '../../molecules/Modal/Modal'
import ISession from '../../../../types/Entities/ISession'
import Button from '../../atoms/Button/Button'
import { formatDate, formatTime } from '../../../../utils/date'
import { MOMENT_DAY_AND_DATE_FORMAT } from '../../../../constants/datetime'
import { COLORS } from '../../../../assets/styles/variables'

interface IProps {
  session: ISession
  handleValidate: () => void
  handleCancel: () => void
  isCancellationPending: boolean
  hasCancellationSuceeded: boolean
}

const Wrapper = styled.div`
  color: ${COLORS.black};
  text-align: center;
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 20px;
`

const Subtitle = styled.p`
  margin-bottom: 20px;
`

const Info = styled.p`
  color: ${COLORS.gray};
`

const Warning = styled.strong`
  color: ${COLORS.lightRed};
`

const DateInformation = styled.strong`
  color: ${COLORS.black};
  text-transform: capitalize;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  & :nth-child(1) + * {
    margin-left: 10px;
  }
`

export default function SessionCancellationModal({
  session,
  handleValidate,
  handleCancel,
  isCancellationPending,
  hasCancellationSuceeded
}: IProps) {
  const sessionInLessThan12H = useMemo(() => {
    const twelveHoursFromNow = moment().add(12, 'hours')
    const sessionDate = moment(`${session.class.date} ${session.class.time}`)

    return sessionDate.isSameOrBefore(twelveHoursFromNow)
  }, [session.class.date, session.class.time])

  return (
    <Modal>
      <Wrapper>
        <Title>Annulation de la session</Title>

        {hasCancellationSuceeded ? (
          <>
            <Subtitle>
              Votre annulation a bien été prise en compte.{' '}
              {!sessionInLessThan12H && 'vos crédits vous ont été restitués.'}
            </Subtitle>
          </>
        ) : (
          <>
            <Subtitle>
              Voulez vous vraiment annuler cette session du{' '}
              <DateInformation>
                {formatDate(session.class.date, MOMENT_DAY_AND_DATE_FORMAT)}
              </DateInformation>{' '}
              à <DateInformation>{formatTime(session.class.time)} ?</DateInformation>
            </Subtitle>

            {sessionInLessThan12H ? (
              <Warning>
                Cette session est dans moins de 12 heures, votre crédit ne vous sera donc pas
                remboursé.
              </Warning>
            ) : (
              <Info>Si vous décidez d'annuler, votre crédit vous sera restitué.</Info>
            )}
          </>
        )}

        <ButtonsWrapper>
          {!hasCancellationSuceeded && (
            <Button
              onClick={handleValidate}
              className="primary"
              label="Valider l'annulation"
              disabled={isCancellationPending}
            />
          )}
          <Button onClick={handleCancel} className="tertiary" label="Fermer" />
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  )
}
