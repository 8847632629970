import React from 'react'

import SectionCoachs from '../components/presentationals/organisms/SectionCoachs/SectionCoachs'
import useCoachs from '../hooks/build/useCoachs'

function SectionCoachsContainer() {
  const coachs = useCoachs()

  return <SectionCoachs coachs={coachs} />
}

export default SectionCoachsContainer
