import React, { FC } from 'react';
import styled from 'styled-components';

import { Heading3 } from '../../tokens/Font/Font';

const Wrapper = styled.li``

const Title = styled.h3`
  ${Heading3};
  margin-bottom: 20px;
`

interface IProps {
  title: string
  id: string
  children: any
  className?: string
  as?: 'li' | 'div'
}

const Question: FC<IProps> = ({ className, children, title, id, as }) => (
  <Wrapper className={className} as={as}>
    <Title id={id}>{title}</Title>
    <p>{children}</p>
  </Wrapper>
)

export default Question
