import axios from 'axios'

import { API_PACKS_ALL } from '../../constants/api'
import UserStore from '../../store/models/user'
import IPack from '../../types/Entities/IPack'

const fetchAll = async (): Promise<IPack[]> => {
  let headers = {}

  if (UserStore.jwt) {
    headers = { Authorization: `Bearer ${UserStore.jwt}` }
  }

  const response = await axios({
    method: 'GET',
    url: API_PACKS_ALL,
    headers
  })

  return response.data
}

export default fetchAll
