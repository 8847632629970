import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

import IEvent from '../../types/Entities/IEvent'


const useEvents = (): IEvent[] => {
  const events = useStaticQuery(
    graphql`
      query Events {
        allStrapiEvent {
          nodes {
            id
            date
            title
            text
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return get(events, 'allStrapiEvent.nodes', [])
}

export default useEvents
