import React from 'react'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import SectionTextHightlight from '../components/presentationals/organisms/SectionTextHightlight/SectionTextHightlight'
import useStudio from '../hooks/build/useStudio'
import Page from '../layout/Page'

export default () => {
  const data = useStudio()
  return (
    <Page>
      <Container>
        <Title>{data.title}</Title>
        {data.blocks.map(block =>
          block.title ? (
            <SectionImageAndText
              key={`Experience_block_${block.id}`}
              title={block.title}
              text={block.text}
              image={block.image.childImageSharp}
              flip={block.imagePosition === 'left'}
            />
          ) : (
            <SectionTextHightlight text={block.text} />
          )
        )}
      </Container>
    </Page>
  )
}
