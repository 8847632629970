import React, { useState, useCallback } from 'react'

import BlockWithTitle from '../../components/presentationals/atoms/BlockWithTitle/BlockWithTitle'
import NextSession from '../../containers/NextSession'
import NextSessions from '../../containers/NextSessions'
import Layout from './Layout'
import cancel from '../../api/sessions/cancel'
import SessionCancellationModal from '../../components/presentationals/organisms/SessionCancellationModal/SessionCancellationModal'
import ISession from '../../types/Entities/ISession'

const SumUp = () => {
  const [sessionToCancel, setSessionToCancel] = useState<ISession | null>(null)
  const [showCancellationModal, setShowCancellationModal] = useState(false)
  const [isCancellationPending, setIsCancellationPending] = useState(false)
  const [hasCancellationSuceeded, setHasCancellationSuceeded] = useState(false)

  const handleModalOpening = useCallback((session: ISession) => {
    setSessionToCancel(session)
    setShowCancellationModal(true)
  }, [])

  const handleModalClosing = useCallback(() => {
    setSessionToCancel(null)
    setShowCancellationModal(false)

    if (hasCancellationSuceeded) {
      setHasCancellationSuceeded(false)
      location.reload()
    }
  }, [hasCancellationSuceeded])

  const handleModalValidate = useCallback(() => {
    if (sessionToCancel?.id) {
      setIsCancellationPending(true)

      cancel(sessionToCancel?.id).then(() => {
        setHasCancellationSuceeded(true)
        setIsCancellationPending(false)
      })
    }
  }, [sessionToCancel?.id])

  return (
    <>
      {showCancellationModal && sessionToCancel && (
        <SessionCancellationModal
          session={sessionToCancel}
          handleValidate={handleModalValidate}
          handleCancel={handleModalClosing}
          isCancellationPending={isCancellationPending}
          hasCancellationSuceeded={hasCancellationSuceeded}
        />
      )}

      <Layout>
        <BlockWithTitle title="Prochaine session">
          <NextSession handleSessionCancellation={handleModalOpening} />
        </BlockWithTitle>

        <BlockWithTitle title="Autres sessions réservées">
          <NextSessions handleSessionCancellation={handleModalOpening} />
        </BlockWithTitle>
      </Layout>
    </>
  )
}

export default SumUp
