import GatsbyLink from 'gatsby-link'
import React from 'react'
import styled, { css } from 'styled-components'

import BlockWithTitle from '../../components/presentationals/atoms/BlockWithTitle/BlockWithTitle'
import { LinkLightBg } from '../../components/presentationals/tokens/Link/Link'
import InfoFormContainer from '../../containers/InfoForm'
import PasswordFormContainer from '../../containers/PasswordForm'
import Layout from './Layout'

const LinkCss = css`
  ${LinkLightBg};
  display: block;
  margin-bottom: 20px;
`
const ContactLink = styled.a`
  ${LinkCss};
`
const DeleteAccountLink = styled(GatsbyLink)`
  ${LinkCss}
`

const CONTACT_HREF = 'mailto:kevin.dupouy@journeystudio.fr'

const Profile = () => (
  <Layout>
    <BlockWithTitle title="Mes informations">
      <InfoFormContainer />
    </BlockWithTitle>
    <BlockWithTitle title="Mot de passe">
      <PasswordFormContainer />
    </BlockWithTitle>
    <BlockWithTitle title="Mes données">
      <ContactLink href={CONTACT_HREF}>
        Contacter le Délégué à la Protection des Données
      </ContactLink>
      <DeleteAccountLink to="/faq#supprimer-mon-compte">Supprimer mon compte</DeleteAccountLink>
    </BlockWithTitle>
  </Layout>
)

export default Profile
