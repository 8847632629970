import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import book from '../api/sessions/book'
import { media } from '../assets/styles/mixins'
import { COLORS } from '../assets/styles/variables'
import Back from '../components/presentationals/atoms/Back/Back'
import Container from '../components/presentationals/atoms/Container/Container'
import PageLoader from '../components/presentationals/atoms/PageLoader/PageLoader'
import BikesForm, {
  IValues as IBikeFormValues
} from '../components/presentationals/formik/forms/Bikes/NewBikesPlan'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4
} from '../components/presentationals/tokens/Font/Font'
import { STRAPI_TIME_FORMAT } from '../constants/datetime'
import { ROUTE_SESSION_SELECT } from '../constants/routes'
import useClassesStore from '../hooks/runtime/useClassesStore'
import useSessionsStore from '../hooks/runtime/useSessionsStore'
import { getAvailableBikes } from '../types/Entities/IClass'
import ICoach from '../types/Entities/ICoach'
import ISession from '../types/Entities/ISession'
import { isLoading } from '../types/ILoadingState'

interface IProps {
  identifier: string
}

const Title = styled.h1`
  ${Heading2}
  margin: 60px 0 10px;

  ${media.md`
    ${Heading1};
    margin: 80px 0 10px;
  `}
`

const SubTitle = styled.h2`
  ${Heading4};
  max-width: 870px;
  margin-bottom: 60px;

  ${media.md`
    ${Heading3};
    margin-bottom: 80px
  `};
`

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
  padding-top: 30px;

  ${media.md`
    padding-top: 30px;
  `}
`

const StyledBack = styled(Back)`
  top: 30px;
  left: 16px;
  position: relative;
`

const Sorry = styled.div`
  ${Heading4}
  margin-bottom: 30px;
`

const BikesWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`

const Error = styled.div`
  color: ${COLORS.lightRed};
  margin: 20px auto 40px;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Booked = styled.div`
  color: ${COLORS.gray.copy};
  margin-bottom: 60px;
`

enum IBookingState {
  'pending',
  'booking',
  'booked'
}

function BookClass({ identifier }: IProps) {
  const [bookingState, setBookingState] = useState(IBookingState.pending)

  const [error, setError] = useState('')

  const classesStore = useClassesStore()
  const sessionsStore = useSessionsStore()

  const selectedClass = classesStore.classesByIdentifier[identifier]

  // Fetch class with given identifier
  useEffect(() => {
    classesStore.fetchClassByIdentifierIfNeeded(identifier)
  }, [identifier])

  const selectedClassState = classesStore.classesByIdentifierState[identifier]

  // Loading state
  if (!selectedClass && isLoading(selectedClassState)) {
    return (
      <Container>
        <Title>Chargement...</Title>
        <PageLoader />
      </Container>
    )
  }

  // No class found for given identifier
  if (!selectedClass) {
    return (
      <Container>
        <Title>Oups !</Title>
        <Sorry>Désolé, nous ne trouvons pas le cours que vous cherchez !</Sorry>
        <Back label="Sessions disponibles" to={ROUTE_SESSION_SELECT} />
      </Container>
    )
  }

  const availableBikes = getAvailableBikes(selectedClass)

  // The class is already full !
  // if class is fully booked but state just switch for booked
  // we go on to show success message
  if (availableBikes.length === 0 && bookingState !== IBookingState.booked) {
    return (
      <Container>
        <Title>Oups !</Title>
        <Sorry>Désolé, ce cours est complet !</Sorry>
        <Back label="Sessions disponibles" to={ROUTE_SESSION_SELECT} />
      </Container>
    )
  }

  // Format date and coach for display
  const date = moment(selectedClass.date).format('dddd DD MMMM')
  const time = moment(selectedClass.time, STRAPI_TIME_FORMAT).format('HH[h]mm')
  const coachs = selectedClass.coaches.map((coach: ICoach) => coach.name).join(' et ')

  const onSubmit = (values: IBikeFormValues, actions: FormikHelpers<IBikeFormValues>) => {
    setBookingState(IBookingState.booking)
    window.scrollTo(0, 0)
    book(identifier, values.bike!)
      .then((data: ISession) => {
        sessionsStore.add(data)
        classesStore.markBikeAsBusy(identifier, values.bike)
        setBookingState(IBookingState.booked)
      })
      .catch(error => {
        setError(error.response.data.message)
        setBookingState(IBookingState.pending)
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  switch (bookingState) {
    case IBookingState.pending: {
      return (
        <>
          <Container>
            <StyledBack label="Annuler" to={ROUTE_SESSION_SELECT} />
            <Title>Choisissez votre bike</Title>
            <SubTitle>
              Session du {date} à {time} avec {coachs}
            </SubTitle>
          </Container>
          <ContainerWhiteBackground fluid>
            {error && <Error>{error}</Error>}
            <BikesWrapper>
              <BikesForm onSubmit={onSubmit} bikes={selectedClass.bikes} />
            </BikesWrapper>
          </ContainerWhiteBackground>
        </>
      )
    }
    case IBookingState.booking: {
      return (
        <Container>
          <StyledBack label="Annuler" to={ROUTE_SESSION_SELECT} />
          <Title>Patientez</Title>
          <SubTitle>Nous envoyons votre demande...</SubTitle>
        </Container>
      )
    }
    case IBookingState.booked: {
      return (
        <>
          <Container>
            <StyledBack label="Continuer" to={ROUTE_SESSION_SELECT} />
            <Title>Session réservée !</Title>
            <SubTitle>
              Session du {date} à {time} avec {coachs}
            </SubTitle>
          </Container>
          <ContainerWhiteBackground fluid>
            <Booked>
              <h2>Félicitation !</h2>
              <p>Un email de confirmation vous a été envoyé.</p>
            </Booked>
          </ContainerWhiteBackground>
        </>
      )
    }
  }
}

export default observer(BookClass)
