import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'

interface SectionHashtagProps {
  className?: string
}

const Wrapper = styled.section`
  flex-direction: column;
  display: flex;
  margin: 60px auto;
  justify-content: center;
  align-items: center;

  ${media.md`
      margin: 100px auto;
  `}
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
  font-family: ${FONTS.brand};
  font-weight: 500;
  font-size: 2rem;
  text-decoration: none;
  transition: ${TRANSITIONS.base};

  ${media.md`
    font-size: 2.875rem
  `};

  ${media.sm`
    font-size: 2.375rem;
  `};

  :hover {
    text-decoration: none;
    color: ${COLORS.brand};
  }
`

const SectionHashtag: FC<SectionHashtagProps> = ({ className }) => (
  <Wrapper className={className}>
    <Link
      title="#ExploreNewWays hashtag"
      href="https://www.instagram.com/explore/tags/explorenewways/"
      target="_blank"
      rel="noopener noreferer"
    >
      #ExploreNewWays
    </Link>
  </Wrapper>
)

export default SectionHashtag
