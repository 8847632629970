import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    identifier: yup
      .string()
      .email()
      .required(),
    password: (yup.string() as any).password().required()
  })

export default validationSchema
