import React from 'react';
import styled from 'styled-components';

import Back from '../components/presentationals/atoms/Back/Back';
import Container from '../components/presentationals/atoms/Container/Container';
import Title from '../components/presentationals/atoms/Title/Title';
import Page from '../layout/Page';

const BackToHome = styled(Back)`
  margin: 60px 0 30px;
`

const NotFoundPage = () => (
  <Page>
    <Container>
      <Title>
        La page n'existe pas.
        <br />
        Êtes-vous perdu ?
      </Title>
      <BackToHome to="/" label="Retour à l'accueil" />
    </Container>
  </Page>
)

export default NotFoundPage
