import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

import ICoach from '../../types/Entities/ICoach'

const useCoachs = (): ICoach[] => {
  const coachs = useStaticQuery(
    graphql`
      query Coaches {
        allStrapiCoach {
          nodes {
            name
            avatar {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return get(coachs, 'allStrapiCoach.nodes', [])
}

export default useCoachs
