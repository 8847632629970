import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import { LinkDarkBg } from '../components/presentationals/tokens/Link/Link'
import useCgv from '../hooks/build/useCgv'
import Page from '../layout/Page'

const Wrapper = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.3rem;
  }
  a {
    ${LinkDarkBg}
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }
`

export default () => {
  const cgv = useCgv()
  return (
    <Page>
      <Container>
        <Title>{cgv.title}</Title>
        <Wrapper>
          <ReactMarkdown source={cgv.text} />
        </Wrapper>
      </Container>
    </Page>
  )
}
