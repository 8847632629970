export const getJWTToken = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.jwt ? JSON.parse(window.localStorage.jwt) : null
  }
  return null
}

export const setJWTToken = (token: string | null) => {
  if (typeof window !== 'undefined') {
    window.localStorage.jwt = JSON.stringify(token)
  }
}

const storage = {
  getJWTToken,
  setJWTToken
}

export default storage
