import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import BlockText from '../../molecules/BlockText/BlockText'

interface IProps {
  className?: string
  title?: string | React.ReactNode
  text?: string | React.ReactNode
}

const Wrapper = styled.section`
  width: 100%;
  display: flex;

  ${media.md`
    flex-direction: row;
  `}
`

const SectionText: FC<IProps> = ({ className, title, text, children }) => (
  <Wrapper className={className}>
    <BlockText title={title} text={text}>
      {children}
    </BlockText>
  </Wrapper>
)

export default SectionText
