import { inject, observer } from 'mobx-react';
import React from 'react';

import InfoForm, { IValues } from '../components/presentationals/formik/forms/Info/Info';

interface IProps {
  userStore?: any
}

const InfoFormContainer = ({ userStore }: IProps) => {
  const { firstname, lastname, email, phone, birthdate, shoes } = userStore.user

  const initialValues = {
    firstname,
    lastname,
    email,
    phone,
    birthdate,
    shoes
  } as IValues

  return (
    <div>
      <InfoForm initialValues={initialValues} onSubmit={() => {}} />
    </div>
  )
}

export default inject(`userStore`)(observer(InfoFormContainer))
