import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS } from '../../../../assets/styles/variables'
import { Body1, Heading3, Heading4, Heading5 } from '../../tokens/Font/Font'
import Button from '../Button/Button'

interface IProps {
  className?: string
  date: string
  title?: string
  coach: string
  time: string
  duration: string
  bike: number
  handleSessionCancellation?: () => void
  as?: 'div' | 'li'
}

const Wrapper = styled.div`
  border: solid 1px ${COLORS.gray.dark};
  color: ${COLORS.gray.dark};
  display: inline-flex;
  flex-direction: column;
  max-width: 270px;
  width: 100%;
  padding: 23px 20px;
  justify-content: space-between;

  ${media.md`
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 26px 32px;
  `}
`

const Date = styled.div`
  font-family: ${FONTS.sansSerif};
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-style: normal;
  line-height: 1.78;
  color: ${COLORS.greenishBlue};
  text-transform: capitalize;

  ${media.md`
    width: 95px;
  `}
`
const Coach = styled.div`
  ${Heading4}

  ${media.md`
    ${Heading3}
    flex: 1;
    margin-left: 80px;
  `}
`

const Title = styled.div`
  ${Heading5}
  margin-bottom: 10px;

  ${media.md`
    ${Heading4}
    margin-bottom: 0px;
  `}
`

const TimeAndBikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${media.md`
  align-items: center;
  `}
`

const TimeAndDuration = styled.div`
  ${Body1};
  display: flex;
  flex-direction: columns;

  ${media.md`
  flex-direction: row;
`};
`

const BikeNumber = styled.div`
  ${Body1};

  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid lightgray;
`

const CancelButtonWrapper = styled.div`
  margin-top: 10px;
`

const SessionItem: FC<IProps> = ({
  className,
  date,
  title,
  coach,
  time,
  duration,
  bike,
  handleSessionCancellation,
  as = 'li'
}) => (
  <Wrapper className={className} as={as}>
    <Date>{date}</Date>

    <Coach>
      {coach}
      {title && <Title>{title}</Title>}
    </Coach>

    <TimeAndBikeWrapper>
      <TimeAndDuration>
        {time} - {duration}
      </TimeAndDuration>
      <BikeNumber>Vélo n°{bike}</BikeNumber>

      {handleSessionCancellation && (
        <CancelButtonWrapper>
          <Button className="tertiary" label="Annuler" onClick={handleSessionCancellation} />
        </CancelButtonWrapper>
      )}
    </TimeAndBikeWrapper>
  </Wrapper>
)

export default SessionItem
