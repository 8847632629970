import axios from 'axios'

import { API_CREDITS_ALL } from '../../constants/api'
import UserStore from '../../store/models/user'
import ICredits from '../../types/Entities/ICredits'

const fetchAll = async (): Promise<ICredits[]> => {
  const response = await axios({
    method: 'GET',
    url: API_CREDITS_ALL,
    headers: { Authorization: `Bearer ${UserStore.jwt}` }
  })

  return response.data
}

export default fetchAll
