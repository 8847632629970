import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS } from '../../../../assets/styles/variables'
import IClass, { getAvailableBikes } from '../../../../types/Entities/IClass'
import { formatTime } from '../../../../utils/date'
import isClassFinished from '../../../../utils/models/isClassFinished'
import Button from '../../atoms/Button/Button'
import { Heading4 } from '../../tokens/Font/Font'

interface IProps {
  className?: string
  classes: IClass[]
  loading: boolean
  hasAvailableCredits: boolean
  availableCreditsLoading: boolean
  onClassClick: (clickedClass: IClass) => void
  onBuyCreditsClick: () => void
  isLoggedIn: boolean
}

const Wrapper = styled.div`
  max-width: 1170px;
  width: 100%;
  margin: auto;
`

const Grid = styled.div`
  min-height: 20px;

  ${media.md`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 2fr 1fr 1fr 2fr;
    grid-column-gap: 10px;
    padding: 0;

    & > *:first-child {
      padding-left: 55px;
    }

    & > *:last-child {
      padding-right: 55px;
    }
  `}
`

const ClassRow = styled(Grid)`
  flex-direction: column;
  min-height: 20px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: ${COLORS.white} !important;
  color: ${COLORS.black};

  ${media.md`
    height: 116px;
  `}
`

const Titles = styled.div`
  display: none;

  ${media.md`
    margin: 20px 0 10px;
    font-size: 1.125rem;
    display: block
  `};
`

const Actions = styled.div`
  padding-right: 20px;
  display: flex;
  justify-content: center;

  ${media.md`
    justify-content: flex-end;
  `}

  & > * {
    width: 140px !important;
  }
`
const Coach = styled.div`
  font-size: 1.875rem !important;
  line-height: 1;
  margin-bottom: 10px;

  ${media.md`
    margin-bottom: 0;
  `}
`

const SessionTitle = styled.div`
  display: flex;
  font-size: 1rem !important;
  line-height: 1;
  margin-bottom: 10px;

  ${media.md`
    margin-bottom: 0;
  `}
`

const DesktopDateAndTime = styled.div`
  display: none;

  ${media.md`
    display: flex;
    flex-direction: row;
  `}
`

const MobileDateAndTime = styled.div`
  display: flex;

  ${media.md`
    display: none;
  `}
`

const Empty = styled.div`
  ${Heading4};
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  height: 117px;
`

const AvailableCreditsLoadingWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default function Classes({
  className,
  classes,
  onClassClick,
  onBuyCreditsClick,
  hasAvailableCredits,
  availableCreditsLoading = true,
  loading = false,
  isLoggedIn
}: IProps) {
  const handleClassClick = (clickedClass: IClass) => () => onClassClick(clickedClass)

  if (loading) {
    return (
      <Empty>
        <CircularProgress color="inherit" />
      </Empty>
    )
  }

  if (classes.length === 0) {
    return <Empty>Aucune session disponible</Empty>
  }

  return (
    <Wrapper className={className}>
      <Grid>
        <Titles>Coach</Titles>
        <Titles></Titles>
        <Titles>Heure</Titles>
        <Titles>Durée</Titles>
        <Titles></Titles>
      </Grid>
      <div>
        {classes.map(c => {
          const isFull = getAvailableBikes(c).length === 0
          const isFinished = isClassFinished(c)
          const isBookable = c.bookable
          const disabled = isFull || isFinished || !isBookable

          const onClick = !hasAvailableCredits
            ? onBuyCreditsClick
            : !disabled
            ? handleClassClick(c)
            : undefined

          const buttonLabel = isFinished
            ? 'Terminée'
            : disabled
            ? 'Complète'
            : !hasAvailableCredits && isLoggedIn
            ? 'Acheter des crédits'
            : 'Choisir'

          return (
            <ClassRow key={`Class_${c.id}`}>
              <Coach>{!!c.coaches.length && c.coaches.map(c => c.name).join(', ')}</Coach>
              <SessionTitle>{c.title || '-'}</SessionTitle>
              <MobileDateAndTime>
                <span>
                  {formatTime(c.time)} - {`${c.duration} min`}
                </span>
              </MobileDateAndTime>
              <DesktopDateAndTime>{formatTime(c.time)}</DesktopDateAndTime>
              <DesktopDateAndTime>{`${c.duration} min`}</DesktopDateAndTime>
              <Actions>
                {availableCreditsLoading ? (
                  <AvailableCreditsLoadingWrapper>
                    <CircularProgress color="inherit" />
                  </AvailableCreditsLoadingWrapper>
                ) : (
                  isBookable && (
                    <Button
                      className="tertiary"
                      label={buttonLabel}
                      disabled={disabled}
                      onClick={onClick}
                    />
                  )
                )}
              </Actions>
            </ClassRow>
          )
        })}
      </div>
    </Wrapper>
  )
}
