import axios from 'axios'

import { IValues } from '../../components/presentationals/formik/forms/Login/Login'
import { API_LOGIN_URL } from '../../constants/api'
import IUser from '../../types/Entities/IUser'

export interface IReturn {
  user: IUser
  jwt: string
}

const login = async ({ identifier, password }: IValues): Promise<IReturn> => {
  const response = await axios.post(API_LOGIN_URL, {
    identifier,
    password
  })

  const { user, jwt } = response.data
  return { user, jwt }
}

export default login
