import axios from 'axios'

import { API_CLASSES_ONE } from '../../constants/api'
import UserStore from '../../store/models/user'

const fetchOne = async (identifier: string) => {
  const url = API_CLASSES_ONE.replace(':id', identifier)
  const response = await axios({
    method: 'GET',
    url,
    headers: { Authorization: `Bearer ${UserStore.jwt}` }
  })

  return response.data
}

export default fetchOne
