import axios from 'axios';

import { API_BUY_PACK } from '../../constants/api';
import UserStore from '../../store/models/user';

const buyPack = async (packId: string): Promise<string> => {
  const response = await axios({
    method: 'POST',
    url: API_BUY_PACK,
    headers: { Authorization: `Bearer ${UserStore.jwt}` },
    data: { packId }
  })

  return response.data.client_secret
}

export default buyPack
