import { navigate } from 'gatsby'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import Classes from '../components/presentationals/molecules/Classes/Classes'
import ClassesDateSlider from '../components/presentationals/molecules/ClassesDateSlider/ClassesDateSlider'
import { ROUTE_PACK_SELECT, ROUTE_SESSION_BOOK } from '../constants/routes'
import useClassesStore from '../hooks/runtime/useClassesStore'
import useCreditsStore from '../hooks/runtime/useCreditsStore'
import useUserStore from '../hooks/runtime/useUserStore'
import IClass from '../types/Entities/IClass'
import { getDefaultDay } from '../utils/date'

function SelectClass() {
  const classesStore = useClassesStore()
  const creditsStore = useCreditsStore()
  const userStore = useUserStore()

  useEffect(() => {
    creditsStore.fetchIfNeeded()
    // Getting selected day at runtime to prevent building page for the wrong date
    classesStore.setSelectedDay(getDefaultDay())
  }, [])

  // Fetch classes when selected date change
  useEffect(() => {
    if (classesStore.selectedDay) classesStore.fetchSelectedDayClassesIfNeeded()
  }, [classesStore.selectedDay])

  // When class is clicked redirect to BOOK to complete process
  const handleClassClick = (clickedClass: IClass) => {
    navigate(ROUTE_SESSION_BOOK.replace(':identifier', clickedClass.id))
  }

  const handleBuyCreditsClick = () => {
    navigate(ROUTE_PACK_SELECT)
  }

  if (!classesStore.selectedDay) return null
  return (
    <>
      <ClassesDateSlider
        currentDay={classesStore.selectedDay}
        days={classesStore.availableDays}
        onDayClick={classesStore.setSelectedDay}
      />
      <Classes
        loading={classesStore.areSelectedDayClassesLoading}
        classes={classesStore.selectedDayClasses}
        onClassClick={handleClassClick}
        onBuyCreditsClick={handleBuyCreditsClick}
        hasAvailableCredits={!!creditsStore.availableCredits.length}
        isLoggedIn={userStore.isLoggedIn}
        availableCreditsLoading={creditsStore.isLoading}
      />
    </>
  )
}

export default observer(SelectClass)
