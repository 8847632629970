import React from 'react'

import PassedSessions from '../../containers/PassedSessions'
import Layout from './Layout'

const Session = () => (
  <Layout>
    <PassedSessions />
  </Layout>
)

export default Session
