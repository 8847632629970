import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { COLORS, FONTS, TRANSITIONS } from '../assets/styles/variables'
import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import SectionImageAndText from '../components/presentationals/organisms/SectionImageAndText/SectionImageAndText'
import useSiteMetadata from '../hooks/build/useSiteMetadata'
import Page from '../layout/Page'

const SocialLink = styled.a`
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-family: ${FONTS.sansSerif};
  color: ${COLORS.white};
  text-decoration: none;
  transition: ${TRANSITIONS.base};
  margin-bottom: 40px;

  span {
    margin-top: 11px;
    font-size: 0.875rem;
  }

  :hover {
    color: ${COLORS.brand};
    text-decoration: none;
  }
`

interface IProps {
  data: {
    image1: {
      childImageSharp: any
    }
  }
}

export default (props: IProps) => {
  const data = useSiteMetadata()
  const { email, tel, address, facebook, instagram } = data.contact
  const formattedTel = tel.replace(/(.{2})/g, ' $1')

  return (
    <Page>
      <Container>
        <Title>
          Besoin de nous
          <br /> contacter ?
        </Title>
        <SectionImageAndText
          image={props.data.image1.childImageSharp}
          title="Retrouvez-nous tous les jours au Studio !"
          text={`Journey Studio Cycle  
          ${address}  
          Sinon, on reste disponibles par téléphone au [${formattedTel}](tel:${tel}) ou par email, sur [${email}](mailto:${email})
          `}
        >
          <SocialLink
            href="https://www.facebook.com/journeystudiocycle"
            target="_blank"
            title="facebook"
          >
            Facebook
            <br />
            <span>{facebook}</span>
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/journeystudiocycle"
            target="_blank"
            title="facebook"
          >
            Instagram
            <br />
            <span>{instagram}</span>
          </SocialLink>
        </SectionImageAndText>
        <SectionHashtag />
      </Container>
    </Page>
  )
}

export const query = graphql`
  query ContactQuery {
    image1: file(relativePath: { eq: "contact_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
