import React from 'react'

import BuyPacks from '../../containers/BuyPacks'
import Page from '../../layout/Page'

interface IProps {
  identifier: string
}
export default function({ identifier }: IProps) {
  return (
    <Page>
      <BuyPacks identifier={identifier} />
    </Page>
  )
}
