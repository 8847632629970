export enum ILoadingState {
  pending,
  loading,
  success,
  error,
  canceled
}

export const isLoading = (state?: ILoadingState) =>
  state !== ILoadingState.error &&
  state !== ILoadingState.success &&
  state !== ILoadingState.canceled
