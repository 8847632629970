import { navigateTo } from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { ROUTE_PACK_BUY } from '../../../../constants/routes'
import IPack from '../../../../types/Entities/IPack'
import Item from '../../molecules/Pack/Pack'
import { Body1, Heading2 } from '../../tokens/Font/Font'

const Group = styled.section`
  padding-top: 60px;

  ${media.md`
    padding-top: 100px;  
  `}
`

const GroupTitle = styled.h2`
  ${Heading2}
`

const GroupSubTitle = styled.p`
  ${Body1}
  margin-top: 10px;
`

const List = styled.ul`
  fisplay: flex;
  flex-wrap: wrap;
  padding-top: 40px;

  & > * {
    margin: 5px;
  }
`

interface IProps {
  title: string
  subtitle?: string
  packs: IPack[]
  disableButtons?: boolean
}

const handlePackClicked = (pack: IPack) => () =>
  navigateTo(ROUTE_PACK_BUY.replace(':identifier', pack.id))

const BlockPacks = (props: IProps) => {
  if (!props.packs.length) {
    return null
  }

  const { title, subtitle, packs, disableButtons = false } = props

  return (
    <Group>
      <GroupTitle>{title}</GroupTitle>
      {subtitle && <GroupSubTitle>{subtitle}</GroupSubTitle>}
      <List>
        {packs.map(pack => (
          <Item
            key={pack.id}
            title={pack.name}
            subtitle={pack.price}
            description={pack.description}
            buttonLabel={
              disableButtons ? 'Achat temporairement indisponible' : 'Choisir et régler *'
            }
            onButtonClicked={handlePackClicked(pack)}
            disableButton={disableButtons}
          />
        ))}
      </List>
    </Group>
  )
}

export default BlockPacks
