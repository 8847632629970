import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { COLORS } from '../assets/styles/variables'
import BlockWithTitle from '../components/presentationals/atoms/BlockWithTitle/BlockWithTitle'
import SessionItem from '../components/presentationals/atoms/SessionItem/SessionItem'
import SessionList from '../components/presentationals/atoms/SessionList/SessionList'
import { MOMENT_DAY_AND_DATE_FORMAT } from '../constants/datetime'
import { SessionsModel } from '../store/models/sessions'
import ISession from '../types/Entities/ISession'
import { formatDate, formatTime } from '../utils/date'

const Empty = styled.div``
const StyledSessionList = styled(SessionList)`
  & > *:nth-child(even) {
    background: ${COLORS.ice};
  }
`

interface IProps {
  sessionsStore?: SessionsModel
}

const PassedSessions = ({ sessionsStore }: IProps) => {
  useEffect(() => {
    sessionsStore!.fetchIfNeeded()
  }, [])

  if (sessionsStore!.isLoading) {
    return (
      <BlockWithTitle title="Sessions passées !">
        <div>Chargement...</div>
      </BlockWithTitle>
    )
  }

  if (!sessionsStore!.passedSessions.length) {
    return (
      <BlockWithTitle title="Qu'attendez-vous ?">
        <Empty>Vous n'avez pas encore fait de session.</Empty>
      </BlockWithTitle>
    )
  }

  return (
    <BlockWithTitle title="Bravo !">
      <StyledSessionList>
        {sessionsStore!.passedSessions.map((session: ISession) => (
          <SessionItem
            key={`PassedSessions_${session.id}`}
            date={formatDate(session.class.date, MOMENT_DAY_AND_DATE_FORMAT)}
            coach={session.class.coaches.map(c => c.name).join(', ')}
            title={session.class.title}
            time={formatTime(session.class.time)}
            duration={`${session.class.duration} min`}
            bike={session.bike}
          />
        ))}
      </StyledSessionList>
    </BlockWithTitle>
  )
}

export default inject(`sessionsStore`)(observer(PassedSessions))
