import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import SessionItem from '../components/presentationals/atoms/SessionItem/SessionItem'
import { MOMENT_DAY_AND_DATE_FORMAT } from '../constants/datetime'
import useSessionsStore from '../hooks/runtime/useSessionsStore'
import ISession from '../types/Entities/ISession'
import { formatDate, formatTime } from '../utils/date'

const Empty = styled.div``

interface IProps {
  handleSessionCancellation?: (session: ISession) => void
}

function NextSessions({ handleSessionCancellation }: IProps) {
  const sessionsStore = useSessionsStore()

  // Client-side Runtime Data Fetching
  useEffect(() => {
    sessionsStore.fetchIfNeeded()
  }, [])

  if (sessionsStore.isLoading) {
    return <div>Chargement...</div>
  }

  if (!sessionsStore.nextSessions.length) {
    return <Empty>Vous n'avez pas de session à venir.</Empty>
  }

  const session: ISession = sessionsStore.nextSessions.sort((a: ISession, b: ISession) => {
    const aDate = moment(`${a.class.date} ${a.class.time}`)
    const bDate = moment(`${b.class.date} ${b.class.time}`)

    return aDate.isBefore(bDate) ? -1 : 1
  })[0]

  const cancel = handleSessionCancellation ? () => handleSessionCancellation(session) : undefined

  return (
    <SessionItem
      handleSessionCancellation={cancel}
      key={`NextSession_${session.id}`}
      date={formatDate(session.class.date, MOMENT_DAY_AND_DATE_FORMAT)}
      coach={session.class.coaches.map(c => c.name).join(', ')}
      title={session.class.title}
      time={formatTime(session.class.time)}
      duration={`${session.class.duration} min`}
      bike={session.bike}
    />
  )
}

export default observer(NextSessions)
