import React from "react"

function BurgerClose(props) {
  return (
    <svg width={21} height={21} {...props}>
      <path
        d="M20.4 2.7L18.3.6l-7.8 7.8L2.7.6.6 2.7l7.8 7.8-7.8 7.8 2.1 2.1 7.8-7.8 7.8 7.8 2.1-2.1-7.8-7.8z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default BurgerClose
