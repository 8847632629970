import { Router } from '@reach/router'
import React from 'react'

import Route from '../components/presentationals/Route'
import { ROUTE_SESSION_BOOK, ROUTE_SESSION_SELECT } from '../constants/routes'
import PrivateRoute from '../containers/PrivateRoute'
import Book from '../pagesDynamics/sessions/Book'
import Select from '../pagesDynamics/sessions/Select'

export default () => (
  <Router>
    <Route path={ROUTE_SESSION_SELECT} component={Select} />
    <PrivateRoute path={ROUTE_SESSION_BOOK} component={Book} />
  </Router>
)
