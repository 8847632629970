import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'

import Button from '../../../atoms/Button/Button'
import TextField from '../../fields/TextField/TextField'
import validationSchema from './Login.validationSchema'

const INITIAL_VALUES = {
  identifier: '',
  password: ''
}

interface IProps {
  className?: string
  onSubmit: (values: IValues, actions: FormikHelpers<IValues>) => void
}

export interface IValues {
  identifier: string
  password: string
}

export default function LoginForm({ onSubmit, className }: IProps) {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, handleSubmit }: FormikProps<IValues>) => (
        <Form className={className} onSubmit={handleSubmit}>
          <Field
            fullWidth
            required
            name="identifier"
            label="Adresse mail"
            placeholder="Votre adresse email"
            type="email"
            component={TextField}
          />
          <Field
            fullWidth
            required
            name="password"
            label="Mot de passe"
            placeholder="Votre mot de passe"
            type="password"
            component={TextField}
          />
          <Button type="submit" label="Je me connecte" disabled={!isValid || isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}
