import BaseLink from 'gatsby-link';
import React, { FC } from 'react';
import styled from 'styled-components';

import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables';

interface NavigationProps {
  menuItems: { to: string; label: string }[]
  className?: string
}

const Nav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`

const NavItem = styled.li`
  padding-right: 30px;
  display: flex;
  align-items: center;
`

const Link = styled(BaseLink)`
  text-decoration: none;
  font-family: ${FONTS.sansSerif};
  font-size: 1rem;
  font-weight: 500;
  color: ${COLORS.white};
  position: relative;
  transition: ${TRANSITIONS.base};

  &.active,
  :focus,
  :active,
  :hover {
    text-decoration: none;
    color: ${COLORS.brand};
  }

  :after {
    transition: ${TRANSITIONS.base};
    opacity: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -9px;
    border: 1px solid ${COLORS.brand};
  }

  &.active:after,
  :focus:after,
  :active:after,
  :hover:after {
    opacity: 1;
  }
`

const Navigation: FC<NavigationProps> = ({ className, menuItems }) => (
  <Nav className={className}>
    {menuItems.map((menuItem, index) => (
      <NavItem key={`MenuItem_${index}_${menuItem.label}`}>
        <Link to={menuItem.to} activeClassName="active">
          {menuItem.label}
        </Link>
      </NavItem>
    ))}
  </Nav>
)

export default Navigation
