import axios from 'axios'

import { API_SESSIONS_ALL } from '../../constants/api'
import UserStore from '../../store/models/user'
import ISession from '../../types/Entities/ISession'

const fetchAll = async (): Promise<ISession[]> => {
  const response = await axios({
    method: 'GET',
    url: API_SESSIONS_ALL,
    headers: { Authorization: `Bearer ${UserStore.jwt}` }
  })

  return response.data
}

export default fetchAll
