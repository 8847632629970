import baseMoment, { Moment } from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(baseMoment as any)

export default (date: Moment) => {
  // Create the range - start Monday
  const start = date.clone().startOf('isoWeek')

  const end = start.clone().add(2, 'weeks')

  const range = moment.range(start, end)

  // Return array of results
  return Array.from(range.by('day', { excludeEnd: false }))
}
