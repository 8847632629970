export const ROUTE_ACCOUNT = `/mon-compte`
export const ROUTE_LOGIN = `/connectez-vous`
export const ROUTE_REGISTER = `/creer-un-compte`

export const ROUTE_SESSION_SELECT = '/choisissez-votre-session'
export const ROUTE_SESSION_BOOK = `${ROUTE_SESSION_SELECT}/:identifier`

export const ROUTE_PACK_SELECT = '/packs'
export const ROUTE_PACK_BUY = `${ROUTE_PACK_SELECT}/:identifier`

export const ROUTE_ACCOUNT_PROFILE = `${ROUTE_ACCOUNT}/profile`
export const ROUTE_ACCOUNT_SESSIONS = `${ROUTE_ACCOUNT}/sessions`
export const ROUTE_ACCOUNT_SUM_UP = `${ROUTE_ACCOUNT}/resume`
export const ROUTE_ACCOUNT_PACKS = `${ROUTE_ACCOUNT}/credits`

export const ROUTE_COACHS = '/coachs'
export const ROUTE_CGV = '/conditions-generales-de-vente'

export const ROUTE_DISCOVER_FIRST_SESSION = '/premiere-session'
export const ROUTE_EXPERIENCE = '/experience'
export const ROUTE_STUDIO = '/studio'
