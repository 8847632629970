import Link from 'gatsby-link'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables'
import navigation from '../../../../constants/navigation'

const Wrapper = styled.footer`
  margin-top: 100px;
  padding: 30px 0 55px 0;
  position: relative;
  display: flex;

  ${media.md`
    margin-top: 150px;
    padding: 60px 0 120px 0;
  `}
    :before {
    content: '';
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #939393;
    outline: 1px solid transparent;
    -webkit-backface-visibility: hidden;
    -ms-transform: skewY(-3deg);
    -webkit-transform: skewY(-3deg);
    transform: skewY(-3deg);
  }
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  width: 100%;
  margin: auto;
  flex-direction: column-reverse;
  padding: 0 25px;

  ${media.md`
    flex-direction: row;
  `}
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  ${media.md`
    margin-bottom: 0;
    flex-direction: row;
  `}
`
const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  display: list-item;
  margin-bottom: 28px;

  ${media.sm`
    margin: 0;
    display: flex;
  `}
`

const MenuItem = styled.li`
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;

  ${media.sm`
    padding-right: 30px;
    margin-bottom: 50px;
  `}
`

const MenuItemLinkCss = css`
  text-decoration: none;
  font-family: ${FONTS.sansSerif};
  font-size: 1em;
  transition: ${TRANSITIONS.base};
  color: ${COLORS.white};

  &.active,
  :focus,
  :active,
  :hover {
    text-decoration: none;
    color: ${COLORS.brand};
  }
`

const MenuItemLink = styled(Link)`
  ${MenuItemLinkCss}
`

const PressLink = styled.a`
  ${MenuItemLinkCss}
`

const MenuSmallItem = styled(MenuItem)`
  font-size: 0.875rem;
  font-weight: normal;

  ${media.sm`
    margin-bottom: 0;
  `}
`

const SocialLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-family: ${FONTS.sansSerif};
  color: ${COLORS.white};
  text-decoration: none;
  transition: ${TRANSITIONS.base};
  margin: 0 0 34px 0;

  ${media.md`
    margin: 0 0 0 65px;
  `}

  span {
    margin-top: 11px;
    font-size: 0.875rem;
  }

  :hover {
    color: ${COLORS.brand};
    text-decoration: none;
  }
`

interface IFooter {
  instagram: string
  facebook: string
}

const Footer: FC<IFooter> = ({ facebook, instagram }) => (
  <Wrapper>
    <Content>
      <Left>
        <Menu>
          {navigation.footer.main.map(menuItem => (
            <MenuItem key={`Footer_MenuMain_${menuItem.label}`}>
              <MenuItemLink to={menuItem.to} activeClassName="active">
                {menuItem.label}
              </MenuItemLink>
            </MenuItem>
          ))}
        </Menu>
        <Menu>
          <MenuSmallItem>© Journey Studio Cycle 2020</MenuSmallItem>
          {navigation.footer.secondary.map(menuItem => (
            <MenuSmallItem key={`Footer_MenuSecondary_${menuItem.label}`}>
              <MenuItemLink to={menuItem.to} activeClassName="active">
                {menuItem.label}
              </MenuItemLink>
            </MenuSmallItem>
          ))}
          <MenuSmallItem>
            <PressLink
              href="https://drive.google.com/drive/folders/11CBiXUa7G_l7NztHh1lf00X6valy0WtR"
              target="_blank"
              rel="noopener noreferrer"
            >
              Presse
            </PressLink>
          </MenuSmallItem>
        </Menu>
      </Left>
      <Right>
        <SocialLink
          href="https://www.facebook.com/journeystudiocycle"
          target="_blank"
          title="facebook"
        >
          Facebook
          <br />
          <span>{facebook}</span>
        </SocialLink>
        <SocialLink
          href="https://www.instagram.com/journeystudiocycle"
          target="_blank"
          title="instagram"
        >
          Instagram
          <br />
          <span>{instagram}</span>
        </SocialLink>
      </Right>
    </Content>
  </Wrapper>
)

export default Footer
