import { navigate } from 'gatsby'
import { inject, observer } from 'mobx-react'
import React from 'react'

import PageLoader from '../components/presentationals/atoms/PageLoader/PageLoader'
import { ROUTE_ACCOUNT_PROFILE, ROUTE_LOGIN, ROUTE_REGISTER } from '../constants/routes'
import Page from '../layout/Page'
import isSSR from '../utils/isSSR'

interface IProps {
  store: any
  children: any
}

/**
 * RefreshUserIfNeeded
 *
 * This component aims to check user status and react accordingly
 * It will :
 * - Refrech / Fetch current user
 * - Redirects if needed to login page or logged area
 * - Render loading while during the fetch
 * - Render it's children otherwise fetched
 */
const RefreshUserIfNeeded = ({ userStore, children }: IProps & any) => {
  if (isSSR()) {
    return null
  }

  if (userStore.needToFetchUser) {
    userStore.fetchUser()
  }

  if (userStore.isLoading) {
    return (
      <Page>
        <PageLoader />
      </Page>
    )
  }

  const authenticationRoutes = [ROUTE_LOGIN, ROUTE_REGISTER]

  if (!userStore.isLoggedIn && !authenticationRoutes.includes(window.location.pathname)) {
    navigate(ROUTE_LOGIN)
    return null
  }

  if (userStore.isLoggedIn && authenticationRoutes.includes(window.location.pathname)) {
    navigate(ROUTE_ACCOUNT_PROFILE)
    return null
  }

  return <>{children}</>
}

export default inject(`userStore`)(observer(RefreshUserIfNeeded))
