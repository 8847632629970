import ILink from '../types/ILink'

interface INavigation {
  header: ILink[]
  footer: {
    main: ILink[]
    secondary: ILink[]
  }
}

const navigation: INavigation = {
  header: [
    {
      label: "L'Expérience",
      to: '/experience'
    },
    {
      label: 'Crédits',
      to: '/packs'
    },
    {
      label: 'Studio',
      to: '/studio'
    },
    {
      label: 'Coachs',
      to: '/coachs'
    }
  ],
  footer: {
    main: [
      {
        label: 'Événements',
        to: '/evenements'
      },
      {
        label: 'Recrutement',
        to: '/recrutement'
      },
      {
        label: 'Contact',
        to: '/contact'
      }
    ],
    secondary: [
      {
        label: 'Mentions légales',
        to: '/mentions-legales'
      }
    ]
  }
}

export default navigation
