import { Router } from '@reach/router'
import React from 'react'

import Route from '../components/presentationals/Route'
import { ROUTE_PACK_BUY, ROUTE_PACK_SELECT } from '../constants/routes'
import PrivateRoute from '../containers/PrivateRoute'
import Buy from '../pagesDynamics/packs/Buy'
import Select from '../pagesDynamics/packs/Select'

export default () => (
  <Router>
    <Route path={ROUTE_PACK_SELECT} component={Select} />
    <PrivateRoute path={ROUTE_PACK_BUY} component={Buy} />
  </Router>
)
