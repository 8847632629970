import React, { FC, Fragment } from 'react'

import styles from './Header.module.css'

import HeaderMobile from '../../molecules/HeaderMobile/HeaderMobile'
import HeaderDesktop from '../../molecules/HeaderDesktop/HeaderDesktop'

import navigation from '../../../../constants/navigation'

const Header: FC = () => (
  <Fragment>
    <HeaderMobile className={styles.mobile} menuItems={navigation.header} />
    <HeaderDesktop className={styles.desktop} menuItems={navigation.header} />
  </Fragment>
)

export default Header
