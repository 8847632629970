import React from 'react'
import { Field, Form, Formik, FormikProps, FormikHelpers } from 'formik'
import styled from 'styled-components'

import validationSchema from './ForgottenPasswordModal.validationSchema'
import Modal from '../../molecules/Modal/Modal'
import Button from '../../atoms/Button/Button'
import FormError from '../../atoms/FormError/FormError'
import { COLORS } from '../../../../assets/styles/variables'
import TextField from '../../formik/fields/TextField/TextField'

interface IProps {
  handleValidate: (values: IValues, actions: FormikHelpers<IValues>) => void
  handleCancel: () => void
  isSendingPending: boolean
  hasSendingSuceeded: boolean
  sendingError: string
}

const Wrapper = styled.div`
  color: ${COLORS.black};
  text-align: center;
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 20px;
`

const Subtitle = styled.p`
  margin-bottom: 20px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  & > *:first-of-type {
    margin-right: 10px;
  }
`

export interface IValues {
  email: string
}

export default function ForgottenPasswordModal({
  handleValidate,
  handleCancel,
  isSendingPending,
  hasSendingSuceeded,
  sendingError
}: IProps) {
  return (
    <Modal>
      <Wrapper>
        <Title>Mot de passe oublié</Title>
        <Subtitle>
          {hasSendingSuceeded
            ? "Un lien de réinitialisation de votre mot de passe vous a été envoyé à l'adresse fournie. Pensez à vérifier vos spams !"
            : "Veuillez renseigner votre e-mail afin d'y recevoir un lien de réinitialisation de votre mot de passe."}
        </Subtitle>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema()}
          onSubmit={handleValidate}
        >
          {({ isValid, isSubmitting, handleSubmit }: FormikProps<IValues>) => (
            <Form onSubmit={handleSubmit}>
              {!hasSendingSuceeded && (
                <Field
                  fullWidth
                  required
                  name="email"
                  label="Adresse mail"
                  placeholder="Votre adresse email"
                  type="email"
                  component={TextField}
                />
              )}

              {sendingError && <FormError>{sendingError}</FormError>}

              <ButtonsWrapper>
                {!hasSendingSuceeded && (
                  <Button
                    type="submit"
                    className="primary"
                    label="Réinitialiser mon mot de passe"
                    disabled={!isValid || isSubmitting || isSendingPending}
                  />
                )}

                <Button
                  onClick={handleCancel}
                  className="tertiary"
                  label={hasSendingSuceeded ? 'Fermer' : 'Annuler'}
                />
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  )
}
