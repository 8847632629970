import { graphql, useStaticQuery } from 'gatsby'

import IFirstSession from '../../types/Entities/IExperience'

const useFirstSession = (): IFirstSession => {
  const data = useStaticQuery(
    graphql`
      query FirstSession {
        strapiFirstSession {
          title
          blocks {
            id
            title
            text
            buttonLabel
            buttonLink
            imagePosition
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          buttonLabel
          buttonLink
        }
      }
    `
  )

  return data.strapiFirstSession
}

export default useFirstSession
