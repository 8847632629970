import React from 'react'

import BlockWithTitle from '../../components/presentationals/atoms/BlockWithTitle/BlockWithTitle'
import CreditsAvailable from '../../containers/CreditsAvailable'
import CreditsNonAvailable from '../../containers/CreditsNonAvailable'
import Layout from './Layout'

const SumUp = () => (
  <Layout>
    <BlockWithTitle title="Crédit en cours">
      <CreditsAvailable />
    </BlockWithTitle>
    <CreditsNonAvailable />
  </Layout>
)

export default SumUp
