import GatsbyImage from 'gatsby-image'
import Link from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import { COLORS, FONTS, TRANSITIONS } from '../assets/styles/variables'
import logoTop from '../assets/svg/logo_part_1.svg'
import logoBottom from '../assets/svg/logo_part_2.svg'
import Container from '../components/presentationals/atoms/Container/Container'
import Title from '../components/presentationals/atoms/Title/Title'
import useCoachs from '../hooks/build/useCoachs'
import Page from '../layout/Page'

const Grid = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const Coach = styled(Link)`
  display: flex;
  max-width: 320px;
  width: 100%;
  margin: 15px;
  position: relative;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
  font-family: ${FONTS.sansSerif};
  font-weight: 500;
  font-size: 1.5rem;
  text-decoration: none;

  span {
    z-index: 2;
    position: absolute;
  }

  :before,
  :after {
    content: '';
    background-size: 90%;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center 50%;
  }

  :before {
    background-image: url(${logoTop});
  }
  :after {
    background-image: url(${logoBottom});
  }

  span,
  :before,
  :after {
    opacity: 0;
    transition: ${TRANSITIONS.base};
  }

  :hover span,
  :hover:before,
  :hover:after {
    opacity: 1;
  }

  :hover:before {
    background-position: center 8%;
  }
  :hover:after {
    background-position: center 92%;
  }

  :hover {
    text-decoration: none;
  }

  :hover img {
    opacity: 0.3;
  }
`
const Img = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  transition: ${TRANSITIONS.base};
`

export default function Coachs() {
  const coachs = useCoachs()

  return (
    <Page>
      <Container>
        <Title>
          Là pour vous inspirer.
          <br />
          Vous motiver.
          <br />
          Vous challenger.
          <br />
        </Title>
        <Grid>
          {coachs.map(coach => (
            <Coach key={coach.name} to={`/coachs/${coach.name.toLowerCase()}`}>
              <Img {...coach.avatar.childImageSharp} alt={`Photo de ${coach.name}`} />
              <span>{coach.name}</span>
            </Coach>
          ))}
        </Grid>
      </Container>
    </Page>
  )
}
