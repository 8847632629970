import { createGlobalStyle } from 'styled-components'

import 'modern-normalize'

import { media, onEvent } from './mixins'
import { COLORS, DIMENSIONS, FONTS } from './variables'

// tslint:disable-next-line:no-unused-expression
export default createGlobalStyle`

  #root {
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${DIMENSIONS.fontSize.large}px !important;
    line-height: ${DIMENSIONS.lineHeight.regular} !important;
  }

  main {
    max-width: 1600px;
    margin: auto;
    width: 100%;
  }

  body {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${FONTS.sansSerif} !important;
    color: ${COLORS.white};
    background-color: ${COLORS.black};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  // Set defaults for links
  a {
    text-decoration: none;

    ${onEvent`
      text-decoration: underline;
    `}
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  // Figure elements
  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${COLORS.gray.calm};
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: .25rem .5rem;
    border: 1px solid ${COLORS.gray.calm};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        td {
          background-color: ${COLORS.gray.calm};
        }
        tr {
          background-color: ${COLORS.gray.calm};
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.414rem;
    margin-bottom: .5rem;
    color: inherite;
    font-weight: 600;
    line-height: ${DIMENSIONS.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin-top: 0;
    font-size: ${DIMENSIONS.headingSizes.h1}rem;
  }

  h2 {
    font-size: ${DIMENSIONS.headingSizes.h2}rem;
  }

  h3 {
    font-size: ${DIMENSIONS.headingSizes.h3}rem;
  }

  h4, h5, h6 {
    font-size: ${DIMENSIONS.headingSizes.h4}rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: inherit;
  }

  strong {
    color: ${COLORS.white};
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  
  li {
    list-style: none;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${COLORS.gray.dark};
  }

  blockquote {
    margin: .8rem 0;
    padding: .5rem 1rem;
    border-left: .25rem solid ${COLORS.gray.dark};
    color: ${COLORS.gray.calm};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    ${media.md`
      padding-right: 5rem;
      padding-left: 1.25rem;
    `}
  }

  .Mui-disabled {
    background-color: ${COLORS.ice} !important;
  }
`
