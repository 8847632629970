import React, { FC } from 'react'
import styled from 'styled-components'

import { media } from '../../../../assets/styles/mixins'
import { Heading1, Heading2 } from '../../tokens/Font/Font'

export type ITitleSize = 'xl' | 'l'

interface TitleProps {
  className?: string
  children: React.ReactNode
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span' | 'div'
}

const Wrapper = styled.h1`
  ${Heading2}
  margin: 60px 0;

  ${media.md`
    ${Heading1};
    margin: 80px 0;
  `}
`

const Title: FC<TitleProps> = ({ className, children, as }) => (
  <Wrapper className={className} as={as}>
    {children}
  </Wrapper>
)

export default Title
