import { css } from 'styled-components';

import { COLORS, FONTS, TRANSITIONS } from '../../../../assets/styles/variables';

export const LinkFont = css`
  font-size: 0.875rem;
  line-height: 1.57;
  font-weight: 500;
  font-family: ${FONTS.sansSerif};
  text-decoration: underline;
  transition: ${TRANSITIONS.base};
  cursor: pointer;
  text-underline-position: under;
`

export const LinkDarkBg = css`
  color: ${COLORS.brand};

  &:hover {
    color: ${COLORS.white};
  }

  &:disabled {
    color: ${COLORS.ice};
  }
`

export const LinkLightBg = css`
  color: ${COLORS.brand};

  &:hover {
    color: ${COLORS.brownGrey};
  }

  &:disabled {
    color: ${COLORS.ice};
  }
`
