import { action, computed, decorate, observable, runInAction } from 'mobx'
import moment, { Moment } from 'moment'

import fetchAll from '../../api/class/fetchAll'
import fetchOne from '../../api/class/fetchOne'
import getDays from '../../components/presentationals/molecules/ClassesDateSlider/getDays'
import { STRAPI_DATE_FORMAT } from '../../constants/datetime'
import IClass from '../../types/Entities/IClass'
import { ILoadingState, isLoading } from '../../types/ILoadingState'
import IMap from '../../types/IMap'
import { getDefaultDay } from '../../utils/date'

export class ClassesModel {
  availableDays: Moment[] = getDays(getDefaultDay())
  selectedDay: Moment | null = null
  classesByDay: IMap<IClass[]> = {}
  classesByDayState: IMap<ILoadingState> = {}
  classesByIdentifier: IMap<IClass> = {}
  classesByIdentifierState: IMap<ILoadingState> = {}

  /**
   * forgeDayKey
   *
   * Create a safe day based key to store classes in a Map
   */
  private forgeDayKey(day: Moment) {
    return day.startOf('day').unix()
  }

  /**
   * setClassesByDay
   */
  private setClassesByDay(day: Moment, data: IClass[]) {
    const key = this.forgeDayKey(day)
    this.classesByDay[key] = data
  }

  /**
   * setClassByDay
   */
  private setClassByDay(data: IClass) {
    const date = moment(data.date, STRAPI_DATE_FORMAT)
    const key = this.forgeDayKey(date)

    const currentClass = this.classesByDay[key]

    if (currentClass) {
      const index = currentClass.findIndex(c => c.id === data.id)
      if (index > -1) {
        this.classesByDay[key][index] = data
      }
    }
  }

  /**
   * setClasseByIdentifier
   */
  private setClassByIdentifier(identifier: string, data: IClass) {
    this.classesByIdentifier[identifier] = data
  }

  /**
   * setClassesByDayState
   *
   * Set the Loading state for a day based classes fetch request
   */
  private setClassesByDayState(day: Moment, state: ILoadingState) {
    const key = this.forgeDayKey(day)
    this.classesByDayState[key] = state
  }

  /**
   * setClassesByDayState
   *
   * Set the Loading state for class by identifier fetch request
   */
  private setClassByIdentifierState(identifier: string, state: ILoadingState) {
    this.classesByIdentifierState[identifier] = state
  }

  /**
   * shouldFetchSelectedDayClasses
   *
   * Wether or not the we should fetch classes for the given day
   */
  private shouldFetchSelectedDayClasses(day: Moment) {
    const key = this.forgeDayKey(day)
    const state = this.classesByDayState[key]
    const data = this.classesByDay[key]

    if (data) {
      return false
    }

    return !state || state !== ILoadingState.canceled
  }

  /**
   * shouldFetchClassByIdentifier
   *
   * Wether or not the we should fetch class for the given identifier
   */
  private shouldFetchClassByIdentifier(identifier: string) {
    const state = this.classesByIdentifierState[identifier]
    const data = this.classesByIdentifier[identifier]

    if (data) {
      return false
    }

    return !state || state !== ILoadingState.canceled
  }

  /**
   * fetchSelectedDayClasses
   *
   * The method that fetch classes for a given day.
   */
  private async fetchSelectedDayClasses(day: Moment) {
    this.setClassesByDayState(day, ILoadingState.loading)

    try {
      const data: IClass[] = await fetchAll(day)
      runInAction(() => {
        data.forEach(c => {
          this.setClassByIdentifier(c.id, c)
          this.setClassByIdentifierState(c.id, ILoadingState.success)
        })

        this.setClassesByDay(day, data)
        this.setClassesByDayState(day, ILoadingState.success)
      })
    } catch (error) {
      runInAction(() => {
        this.setClassesByDayState(day, ILoadingState.error)
      })
    }
  }

  /**
   * fetchClassByIdentifier
   *
   * The method that fetch single class for a given identifier.
   */
  private async fetchClassByIdentifier(identifier: string) {
    this.setClassByIdentifierState(identifier, ILoadingState.loading)

    try {
      const data: IClass = await fetchOne(identifier)
      runInAction(() => {
        this.setClassByIdentifier(identifier, data)
        this.setClassByDay(data)
        this.setClassByIdentifierState(identifier, ILoadingState.success)
      })
    } catch (error) {
      runInAction(() => {
        this.setClassByIdentifierState(identifier, ILoadingState.error)
      })
    }
  }

  /**
   * fetchSelectedDayClassesIfNeeded
   *
   * This method triggers a fetch of classes for a given day only if necessary.
   * This method can be called many times without performance issue.
   */
  public async fetchSelectedDayClassesIfNeeded() {
    if (this.selectedDay)
      return this.shouldFetchSelectedDayClasses(this.selectedDay)
        ? this.fetchSelectedDayClasses(this.selectedDay)
        : Promise.resolve()
  }

  public async fetchClassByIdentifierIfNeeded(identifier: string) {
    return this.shouldFetchClassByIdentifier(identifier)
      ? this.fetchClassByIdentifier(identifier)
      : Promise.resolve()
  }

  /**
   * selectedDayClasses
   *
   * Returns an array of classes for a given day.
   * It fallback to empty array if not already set.
   */
  public get selectedDayClasses() {
    if (this.selectedDay) {
      const key = this.forgeDayKey(this.selectedDay)
      return this.classesByDay[key] || []
    }
  }

  /**
   * areSelectedDayClassesLoading
   *
   * Wether or not we can consider being on loading state for a given day.
   * If true, this means that a fetch request is actually performing
   * for the selected day or one is about to.
   */
  public get areSelectedDayClassesLoading() {
    if (this.selectedDay) {
      const key = this.forgeDayKey(this.selectedDay)
      return isLoading(this.classesByDayState[key])
    }
  }

  public isClassesByIdentifierLoading(identifier: string) {
    return isLoading(this.classesByDayState[identifier])
  }

  public setSelectedDay = (day: Moment) => {
    this.selectedDay = day
  }

  public markBikeAsBusy = (identifier: string, bike: number) => {
    const selectedClass = this.classesByIdentifier[identifier]
    selectedClass.bikes[bike] = true

    this.setClassByIdentifier(selectedClass.id, selectedClass)
    this.setClassByDay(selectedClass)
  }
}

decorate(ClassesModel, {
  availableDays: observable,
  classesByDayState: observable,
  classesByDay: observable,
  selectedDay: observable,
  setSelectedDay: action,
  selectedDayClasses: computed,
  areSelectedDayClassesLoading: computed,
  classesByIdentifierState: observable,
  classesByIdentifier: observable,
  isClassesByIdentifierLoading: action,
  markBikeAsBusy: action
})
const ClassesStore = new ClassesModel()
export default ClassesStore
