import { BREACKPOINTS } from '../../../../assets/styles/variables'

export default {
  dots: false,
  infinite: false,
  arrows: true,
  slidesToShow: 8,
  slidesToScroll: 8,
  responsive: [
    {
      breakpoint: BREACKPOINTS.lg,
      settings: {
        slidesToShow: 5.5,
        slidesToScroll: 5
      }
    },
    {
      breakpoint: BREACKPOINTS.md,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 5
      }
    },
    {
      breakpoint: BREACKPOINTS.sm,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3
      }
    }
  ]
}
