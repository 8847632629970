import axios from 'axios'
import omit from 'lodash/omit'

import { IValues } from '../../components/presentationals/formik/forms/Signup/Signup'
import { API_SIGNUP_URL } from '../../constants/api'
import IUser from '../../types/Entities/IUser'

export interface IReturn {
  user: IUser
  jwt: string
}

const signup = async (values: IValues): Promise<IReturn> => {
  const response = await axios.post(API_SIGNUP_URL, {
    ...omit(values, ['confirmPassword']),
    username: values.email,
    birthdate: values.birthdate?.format('YYYY-MM-DD')
  })

  // Debug
  console.debug('signup success:', response.data)

  const { user, jwt } = response.data
  return { user, jwt }
}

export default signup
