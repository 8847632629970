import axios from 'axios'

import { API_ME_URL } from '../../constants/api'
import IUser from '../../types/Entities/IUser'

export interface IReturn {
  user: IUser
  jwt: string
}

const delay = () => new Promise(resolve => setTimeout(resolve, 2000))

const refreshUser = async (token: string): Promise<IReturn> => {
  const fetchUser = () =>
    axios.get(API_ME_URL, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

  const [response] = await Promise.all([fetchUser(), delay()])

  // Debug
  console.debug('refreshUser success:', response.data)

  return { user: response.data, jwt: token }
}

export default refreshUser
