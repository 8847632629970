import { MenuItem } from '@material-ui/core'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { Moment } from 'moment'
import React from 'react'

import { SHOES_SIZES_OPTIONS } from '../../../../../constants/user'
import Button from '../../../atoms/Button/Button'
import DatePickerField from '../../fields/DatePickerField/DatePickerField'
import SelectField from '../../fields/SelectField/SelectField'
import SwitchField from '../../fields/Switch/Switch'
import TextField from '../../fields/TextField/TextField'
import validationSchema from './Signup.validationSchema'

const INITIAL_VALUES = {
  shoes: '',
  newsletter: false
}

interface IProps {
  className?: string
  onSubmit: (values: IValues, action: FormikHelpers<IValues>) => void
}

export interface IValues {
  email?: string
  firstname?: string
  lastname?: string
  phone?: string
  birthdate?: Moment
  shoes?: string
  password?: string
  confirmPassword?: string
  newsletter?: boolean
}

export default function SignupForm({ onSubmit, className }: IProps) {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validateOnBlur
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, handleSubmit }: FormikProps<IValues>) => (
        <Form className={className} onSubmit={handleSubmit}>
          <Field
            fullWidth
            required
            name="email"
            label="Adresse mail"
            placeholder="Votre adresse email"
            type="email"
            component={TextField}
          />
          <Field
            fullWidth
            required
            name="firstname"
            label="Prénom"
            placeholder="Votre prénom"
            type="text"
            component={TextField}
          />
          <Field
            fullWidth
            required
            name="lastname"
            label="Nom"
            placeholder="Votre nom"
            type="text"
            component={TextField}
          />
          <Field
            fullWidth
            required
            name="phone"
            label="Numéro de téléphone"
            placeholder="Votre numéro de téléphone"
            type="phone"
            component={TextField}
          />
          <Field
            fullWidth
            required
            name="birthdate"
            label="Votre date de naissance"
            placeholder="Votre date de naissance"
            component={DatePickerField}
          />
          <Field
            fullWidth
            name="shoes"
            select
            options={SHOES_SIZES_OPTIONS}
            label="Pointure de chaussures"
            placeholder="Pointure de chaussures"
            component={SelectField}
          >
            {SHOES_SIZES_OPTIONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
          <Field
            fullWidth
            name="password"
            label="Votre mot de passe"
            component={TextField}
            type="password"
            helperText="Au moins 1 majuscule, 1 minuscule et 1 chiffre"
            autoComplete="off"
          />
          <Field
            fullWidth
            name="confirmPassword"
            label="Répéter mot de passe"
            component={TextField}
            type="password"
            autoComplete="off"
          />

          <Field
            name="newsletter"
            type="checkbox" // seems required to handle correctly initial values of true
            as={SwitchField}
            label="Je souhaite recevoir les newsletters de Journey"
          />
          <Button
            type="submit"
            label={isSubmitting ? 'Envoie en cours...' : "Journey, c'est parti !"}
            disabled={!isValid || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  )
}
