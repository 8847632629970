import React, { useEffect, useState } from 'react';

interface IState {
  successful?: boolean
  error?: any
}

interface IProps {
  children: React.ReactChildren
  uniqueId: string
  script: string
  loader: any
}

const StripeScriptLoader = ({ children, uniqueId, script, loader = 'Loading...' }: IProps) => {
  const [stripeLoaded, setStripeLoaded] = useState<IState>({})
  useEffect(() => {
    const loadScript = (src: string, uniqueId: string) =>
      new Promise((resolve, reject) => {
        const scriptElement = document.getElementById(uniqueId)

        if (!scriptElement) {
          const script = document.createElement('script')
          script.src = src
          script.id = uniqueId

          const handleLoadScriptSuccess = () => resolve({ successful: true })
          const handleLoadScriptFail = (event: any) => reject({ error: event })

          script.addEventListener('load', handleLoadScriptSuccess, {
            once: true
          })
          script.addEventListener('error', handleLoadScriptFail, { once: true })
          document.head.appendChild(script)
        } else {
          resolve({ successful: true })
        }
      })
    const fetchData = async () => {
      const result = await loadScript(script, uniqueId)
      setStripeLoaded(result as any)
    }
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return stripeLoaded.successful ? children : loader
}
export default StripeScriptLoader
