import { graphql, useStaticQuery } from 'gatsby'

import IStudio from '../../types/Entities/IStudio'

const useStudio = (): IStudio => {
  const data = useStaticQuery(
    graphql`
      query Studio{
        strapiStudio {
          title
          blocks {
            id
            title
            text
            imagePosition
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return data.strapiStudio
}

export default useStudio
