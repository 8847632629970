import GatsbyLink from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'

import { media } from '../assets/styles/mixins'
import { COLORS } from '../assets/styles/variables'
import Container from '../components/presentationals/atoms/Container/Container'
import SectionHashtag from '../components/presentationals/organisms/SectionHashtag/SectionHashtag'
import { ButtonStylePrimary } from '../components/presentationals/tokens/Button/Button'
import { Body1, Heading1, Heading2, Heading3, Heading4 } from '../components/presentationals/tokens/Font/Font'
import { ROUTE_REGISTER } from '../constants/routes'
import LoginFormContainer from '../containers/LoginForm'
import RefreshUserIfNeeded from '../containers/RefreshUserIfNeeded'
import Page from '../layout/Page'

const ContainerWhiteBackground = styled(Container)`
  background-color: ${COLORS.white};
  color: ${COLORS.gray.copy};
`

const Title = styled.h1`
  ${Heading2}
  margin: 60px 0 20px;

  ${media.md`
    ${Heading1}
    margin: 80px 0 40px;
  `}
`

const SubTitle = styled.h2`
  ${Heading4};
  max-width: 870px;
  margin-bottom: 40px;

  ${media.md`
  ${Heading3};
    margin-bottom: 60px
  `};
`

const Forms = styled.div`
  display: flex;
  flex-direction column;
  
  ${media.md`
    flex-direction row;
  `}

  & > * {
    margin-bottom: 60px;

    ${media.md`
      width: 500px;
      margin-bottom: 110px;

      :first-child {
        padding-right: 140px;
        border-right: 1px solid ${COLORS.black};
      }
      :last-child {
        padding-left: 140px;
      }
    `}
  }
`

const NoAccount = styled.div``
const NoAccountTitle = styled.h3`
  ${Heading4};
  margin-bottom: 25px;
`
const NoAccountText = styled.p`
  ${Body1}
  margin-bottom: 30px;
`

const NoAccountLink = styled(GatsbyLink)`
  width: max-content;
  ${ButtonStylePrimary}
`

export default function Login() {
  return (
    <RefreshUserIfNeeded>
      <Page>
        <ContainerWhiteBackground fluid>
          <Title>Connectez-vous</Title>
          <SubTitle>
            Connectez-vous à votre compte Journey pour utiliser votre crédit ou le recharger.
          </SubTitle>
          <Forms>
            <LoginFormContainer />
            <NoAccount>
              <NoAccountTitle>Pas encore de compte ?</NoAccountTitle>
              <NoAccountText>
                Rejoins le club pour réserver ton premier cours et profiter de ton tarif de
                bienvenue.
              </NoAccountText>
              <NoAccountLink to={ROUTE_REGISTER}>
                <span>Créer un compte</span>
              </NoAccountLink>
            </NoAccount>
          </Forms>
        </ContainerWhiteBackground>
        <Container>
          <SectionHashtag />
        </Container>
      </Page>
    </RefreshUserIfNeeded>
  )
}
